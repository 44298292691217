import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentApi, RenewalApi } from '../../../../../assets/js/lb-sdk';

@Component({
    selector: 'app-renewal-result',
    templateUrl: './renewal-result.component.html',
    styleUrls: ['./renewal-result.component.css']
})
export class RenewalResultComponent implements OnInit {
    bodyClasses = 'skin-blue layout-top-nav';
    body: HTMLBodyElement = document.getElementsByTagName('body')[0];
    token;
    orderId;
    success: boolean = false;
    card;
    reference;
    planType;

    //Validation
    emailValid: boolean = true;
    mobileNumberValid: boolean = true;

    provinces = [];
    municipalities = [];

    constructor(
      private route: ActivatedRoute,
      private paymentApi: PaymentApi,
      private renewalApi: RenewalApi,
      private router: Router
    ) {
      this.route.paramMap
        .subscribe((params: any) => {
          console.log('params : ', params);
          this.token = params.params.token;
          this.orderId = params.params.orderId;
        });
      console.log(this.token);
      console.log(this.orderId);
      if (!this.token && !this.orderId) {
        this.router.navigate(['/']);
      }
    }


    ngOnInit() {
        // add the the body classes
        this.body.classList.remove('sidebar-mini');
        this.body.classList.add('skin-blue');
        this.body.classList.add('layout-top-nav');
        this.planType = this.route.snapshot.queryParams['card'];
        if (this.planType === 'G') {
          this.card = 'https://s3-ap-southeast-1.amazonaws.com/maxicare-eshop/img/primagold.png'; 
        } else if (this.planType === 'S') {
          this.card = 'https://s3-ap-southeast-1.amazonaws.com/maxicare-eshop/img/primasilver.png';
        }
        this.reference = this.route.snapshot.queryParams['reference'];
        console.log(this.route.snapshot.queryParams['card']);
    }

    ngOnDestroy() {}
}
