import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing/app-routing.module';
import { FormsModule } from '@angular/forms';

import { MainHeaderComponent } from './main/main-header/main-header.component';
import { MainLeftSideComponent } from './main/main-left-side/main-left-side.component';
import { MainControlSidebarComponent } from './main/main-control-sidebar/main-control-sidebar.component';
import { MainFooterComponent } from './main/main-footer/main-footer.component';
import { ApplicationPipesModule } from '../pipes/pipes.module';

import { PaginationComponent } from './pagination/pagination.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';

// import { UserIdleModule } from 'angular-user-idle';

@NgModule({
    declarations: [
        MainHeaderComponent,
        MainLeftSideComponent,
        MainControlSidebarComponent,
        MainFooterComponent,
        PaginationComponent,
        DeleteModalComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        ApplicationPipesModule,
        FormsModule
        // UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 120}), // count is in seconds
        // UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 10}), // count is in seconds
    ],
    exports: [
        MainHeaderComponent,
        MainLeftSideComponent,
        MainControlSidebarComponent,
        MainFooterComponent,
        PaginationComponent,
        DeleteModalComponent
    ]
})
export class TemplatesModule { }
