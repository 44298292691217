import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'sapDatetime'
})
export class SapDatetimePipe implements PipeTransform {

  transform(date: string, time?: string): any {
    var parseDate = moment(date, 'YYYYMMDD');
    if (time) {
        var parseTime = moment(time, 'hhmmss');
        parseDate.set({
            hour:   parseTime.get('hour'),
            minute: parseTime.get('minute'),
            second: parseTime.get('second')
        })
        return parseDate.format('MM-DD-YYYY hh:mm:ss') ? parseDate.format('MM-DD-YYYY hh:mm:ss') : null;
    } else {
        return parseDate.format('MM-DD-YYYY') !== 'Invalid date' ? parseDate.format('MM-DD-YYYY') : null;
    }
  }

}