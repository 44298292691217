import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-audit-trails-details',
  templateUrl: './audit-trails-details.component.html',
  styleUrls: ['./audit-trails-details.component.css']
})
export class AuditTrailsDetailsComponent implements OnInit {
  data;

  constructor(
    public modal: BsModalRef
  ) {
  }

  ngOnInit() {
    const {
      module,
      endpoint,
      method,
      datetime,
      ipAddress,
      user,
      userId,
      headers,
      params,
      query,
      body,
      statusCode,
      response,
      error
    } = this.data;
    this.data = {
      module,
      endpoint,
      method,
      datetime,
      ipAddress,
      user,
      userId,
      headers: headers ? JSON.parse(headers) : undefined,
      params: params ? JSON.parse(params) : undefined,
      query: query ? JSON.parse(query) : undefined,
      body: body ? JSON.parse(body) : undefined,
      statusCode,
      response: response ? JSON.parse(response) : undefined,
      error: error ? JSON.parse(error) : undefined
    };
  }

  dismiss() {
    this.modal.hide();
  }

  close() {
    this.modal.hide();
  }
}