import { Component, OnInit } from '@angular/core';
import { LoadingAndErrors } from '../../../classes';
import { ConfigurationApi } from '../../../../assets/js/lb-sdk/services/custom/Configuration';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent extends LoadingAndErrors implements OnInit {
  sapConfig;
  smsConfig;
  payorlinkConfig;
  shopConfig;
  cronjobConfig;
  systemConfig;
  auditLogConfig;
  paypalConfig;

  constructor(
    private configurationApi: ConfigurationApi,
    private toast: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.getConfiguration();
  }

  getConfiguration() {
    this.loading.sapConfig = true;
    this.configurationApi
      .find({
        where: { type: 'SAP' },
        order: 'order'
      })
      .pipe(finalize(() => {
        this.loading.sapConfig = false;
      }))
      .subscribe(
        config => {
          this.sapConfig = (config || []).map((settings: any) => {
            settings.newValue = settings.value;
            return settings;
          });
        },
        error => {
          this.errors.sapConfig = error && error.message || 'Loading SAP config failed.';
        }
      );
    this.loading.smsConfig = true;
    this.configurationApi
      .find({
        where: { type: 'SMS' },
        order: 'order'
      })
      .pipe(finalize(() => {
        this.loading.smsConfig = false;
      }))
      .subscribe(
        config => {
          this.smsConfig = (config || []).map((settings: any) => {
            settings.newValue = settings.value;
            return settings;
          });
        },
        error => {
          this.errors.smsConfig = error && error.message || 'Loading SMS config failed.';
        }
      );
      this.loading.payorlinkConfig = true;
      this.configurationApi
        .find({
          where: { type: 'PAYORAPI' },
          order: 'order'
        })
        .pipe(finalize(() => {
          this.loading.payorlinkConfig = false;
        }))
        .subscribe(
          config => {
            this.payorlinkConfig = (config || []).map((settings: any) => {
              settings.newValue = settings.value;
              return settings;
            });
          },
          error => {
            this.errors.payorlinkConfig = error && error.message || 'Loading payorlink config failed.';
          }
        );
      this.loading.shopConfig = true;
      this.configurationApi
        .find({
          where: { type: 'SHOP' },
          order: 'order'
        })
        .pipe(finalize(() => {
          this.loading.shopConfig = false;
        }))
        .subscribe(
          config => {
            this.shopConfig = (config || []).map((settings: any) => {
              settings.newValue = settings.value;
              return settings;
            });
          },
          error => {
            this.errors.shopConfig = error && error.message || 'Loading payorlink config failed.';
          }
        );
      this.loading.cronjobConfig = true;
      this.configurationApi
        .find({
          where: { type: 'CRON' },
          order: 'order'
        })
        .pipe(finalize(() => {
          this.loading.cronjobConfig = false;
        }))
        .subscribe(
          config => {
            this.cronjobConfig = (config || []).map((settings: any) => {
              if (settings.name.indexOf('FREQUENCY') >= 0) {
                settings.value = parseInt(settings.value);
              } else if (settings.name.indexOf('LAST_RUN') >= 0) {
                settings.value = moment(new Date(settings.value)).format('MM/DD/YYYY hh:mm:ss a');
              } else {
                settings.value = settings.value === 'true';
              }
              settings.newValue = settings.value;
              return settings;
            });
          },
          error => {
            this.errors.cronjobConfig = error && error.message || 'Loading CRON config failed.';
          }
        );
        this.loading.systemConfig = true;
        this.configurationApi
          .find({
            where: { type: 'SYSTEM' },
            order: 'order'
          })
          .pipe(finalize(() => {
            this.loading.systemConfig = false;
          }))
          .subscribe(
            config => {
              this.systemConfig = (config || []).map((settings: any) => {
                settings.newValue = settings.value;
                return settings;
              });
            },
            error => {
              this.errors.systemConfig = error && error.message || 'Loading System config failed.';
            }
          );
        this.loading.auditLogConfig = true;
        this.configurationApi
          .find({
            where: { type: 'AUDIT_TRAIL' },
            order: 'order'
          })
          .pipe(finalize(() => {
            this.loading.auditLogConfig = false;
          }))
          .subscribe(
            config => {
              this.auditLogConfig = (config || []).map((settings: any) => {
                settings.value = settings.value === 'true';
                settings.newValue = settings.value;
                return settings;
              });
            },
            error => {
              this.errors.auditLogConfig = error && error.message || 'Loading AMAZON STORAGE config failed.';
            }
          );
        this.loading.paypalConfig = true;
        this.configurationApi
          .find({
            where: { type: 'PAYPAL' },
            order: 'order'
          })
          .pipe(finalize(() => {
            this.loading.paypalConfig = false;
          }))
          .subscribe(
            config => {
              this.paypalConfig = (config || []).map((settings: any) => {
                settings.newValue = settings.value;
                return settings;
              });
            },
            error => {
              this.errors.paypalConfig = error && error.message || 'Loading PAYPAL config failed.';
            }
          );
  }

  submit(form) {
    if (form.valid) {
      this.loading.update = true;
      var sapUpdates = this.sapConfig
        .filter(settings => {
          return settings.value != settings.newValue;
        });
      var smsUpdates = this.smsConfig
        .filter(settings => {
        return settings.value != settings.newValue;
      });
      var payorlinkUpdates = this.payorlinkConfig
        .filter(settings => {
        return settings.value != settings.newValue;
      });
      var shopUpdates = this.shopConfig
        .filter(settings => {
        return settings.value != settings.newValue;
      });
      var cronjobUpdates = this.cronjobConfig
        .filter(settings => {
          return settings.value != settings.newValue;
        });
      var systemUpdates = this.systemConfig
        .filter(settings => {
          return settings.value != settings.newValue;
        });
      var auditLogUpdates = this.auditLogConfig
        .filter(settings => {
          return settings.value != settings.newValue;
        });
      var paypalUpdates = this.paypalConfig
        .filter(settings => {
          return settings.value != settings.newValue;
        });

      if (!sapUpdates.length && !smsUpdates.length && !paypalUpdates.length && !payorlinkUpdates.length && !shopUpdates.length && !cronjobUpdates.length && !systemUpdates.length && !auditLogUpdates.length) {
        this.loading.update = false;
      }
      Observable.forkJoin(
        sapUpdates
        .concat(smsUpdates)
        .concat(payorlinkUpdates)
        .concat(shopUpdates)
        .concat(cronjobUpdates)
        .concat(systemUpdates)
        .concat(auditLogUpdates)
        .concat(paypalUpdates)
        .map(settings => {
          return this.configurationApi
            .patchAttributes(settings.id, {
              value: settings.newValue,
              hash: settings.hash
            });
        })
      )
      .pipe(finalize(() => {
        this.loading.update = false;
        this.getConfiguration();
      }))
      .subscribe(
        responses => {
          this.toast.success('Configuration successfully updated', 'Configuration');
        },
        error => {
          this.toast.error('Failed to update configuration', 'Configuration');
        }
      );
    }
  }

  removeIsRunning(configs) {
    return (configs || [])
      .filter(data => {
        return data && data.name && (data.name.indexOf('IS_RUNNING') <= 0);
      });
  }

  cronIsRunning(name) {
    let configName = name.split('_');
    configName.pop();
    configName.pop();
    configName.push('IS_RUNNING');
    configName = configName.join('_');

    return ((this.cronjobConfig || [])
      .filter(data => {
        return data && data.name && (data.name === configName);
      })[0] || {}).value ? 'RUNNING' : 'NOT RUNNING';
  }

}
