import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthenticationComponent } from '../pages/frontend/authentication/authentication.component';
import { RegisterComponent } from '../pages/frontend/authentication/register/register.component';
import { VerifyComponent } from '../pages/frontend/authentication/verify/verify.component';

import { HomeComponent } from '../pages/frontend/home/home.component';
import { DashboardComponent } from '../pages/frontend/dashboard/dashboard.component';

import { ProfileComponent } from '../pages/frontend/profile/profile.component';

//services
import { LoginRedirectService } from '../services/login-redirect.service';
import { AuthenticationService } from '../services/authentication.service';



//admin module
import { AdminDashboardComponent } from '../pages/admin/admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from '../pages/admin/authentication/login/login.component';
import { UsersComponent } from '../pages/admin/users/users.component';
import { ReportsComponent } from '../pages/admin/reports/reports.component';
import { MembersComponent } from '../pages/admin/members/members.component';
import { ConfigurationComponent } from '../pages/admin/configuration/configuration.component';
import { SMSConfigurationComponent } from '../pages/admin/sms-configuration/sms-list.component';
import { EmailsComponent } from '../pages/admin/emails/emails.component';
import { OtpComponent } from '../pages/admin/otp/otp.component';
import { BranchComponent } from '../pages/admin/branch/branch.component';
import { ResendEmailComponent } from '../pages/admin/resend-email/resend-email.component';
import { EmailLogsComponent } from '../pages/admin/email-logs/email-logs.component';

// static
import { NotFoundComponent } from '../pages/static/not-found/not-found.component'
import { AccessDeniedComponent } from '../pages/static/access-denied/access-denied.component';
import { MaintenanceComponent } from '../pages/static/maintenance/maintenance.component';
import { ForgotPasswordComponent } from '../pages/admin/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../pages/admin/authentication/reset-password/reset-password.component';
import { PageExpiredComponent } from '../pages/static/page-expired/page-expired.component';

// Renewal
import { RenewalComponent } from '../pages/frontend/renewal/token/renewal.component';
import { ManualRenewalComponent } from '../pages/frontend/renewal/manual/manual-renewal.component';
import { RenewalResultComponent } from '../pages/frontend/renewal/result/renewal-result.component';
import { RenewalExpiredComponent } from '../pages/frontend/renewal/expired/expired.component';

//
import { OrComponent } from '../pages/admin/or/or.component';
import { CardReferenceLogsComponent } from '../pages/admin/card-reference-logs/card-reference-logs.component';
import { AuditTrailsComponent } from '../pages/admin/audit-trails/audit-trails.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      // { path: '', redirectTo: 'starter', pathMatch: 'full' },
      { path: 'login', component: AuthenticationComponent },
      { path: 'register', component: RegisterComponent/*, canActivate: [LoginRedirect] */},
      { path: 'profile', component: ProfileComponent, canActivate: [AuthenticationService] },
      // { path: ':username', component: ProfileComponent /*, canActivate: [Authentication] */ },
      // { path: ':username/:repo', component: RepositoryComponent /*, canActivate: [Authentication] */ },
      // { path: '', redirectTo: 'login', pathMatch: 'full' }
      { path: 'forgot-password', component: ForgotPasswordComponent/*, canActivate: [LoginRedirect]*/ },
      { path: 'reset-password', component: ResetPasswordComponent/*, canActivate: [LoginRedirect]*/ },

      // { path: 'renewal', redirectTo: '/maintenance' },
      { path: 'renewal/:token', component: RenewalComponent }, //, component: RenewalComponent
      { path: 'renewal/:token/expired', component: RenewalExpiredComponent }, //, component: RenewalExpiredComponent
      { path: 'renewal/:token/order/:orderId ', component: RenewalResultComponent }, //, component: RenewalResultComponent
      // admin modules
      { path: 'admin', component: AdminDashboardComponent, canActivate: [AuthenticationService] },
      { path: 'admin/login', component: AdminLoginComponent, canActivate: [LoginRedirectService] },
      { path: 'admin/users', component: UsersComponent, canActivate: [AuthenticationService] },
      { path: 'admin/reports', component: ReportsComponent, canActivate: [AuthenticationService] },
      { path: 'admin/members', component: MembersComponent, canActivate: [AuthenticationService] },
      { path: 'admin/finance', redirectTo: '/maintenance' },
      // { path: 'admin/emails', component: EmailsComponent },
      { path: 'admin/marketing', redirectTo: '/maintenance' },
      { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthenticationService] },
      { path: 'admin/sms-configuration', component: SMSConfigurationComponent, canActivate: [AuthenticationService] },
      { path: 'admin/emails', component: EmailsComponent, canActivate: [AuthenticationService] },
      { path: 'admin/OTP', component: OtpComponent, canActivate: [AuthenticationService] },
      { path: 'admin/branches', component: BranchComponent, canActivate: [AuthenticationService] },
      { path: 'admin/resend-email', component: ResendEmailComponent, canActivate: [AuthenticationService] },
      { path: 'admin/email-logs', component: EmailLogsComponent, canActivate: [AuthenticationService] },
      
      { path: 'admin/or', component: OrComponent, canActivate: [AuthenticationService] },
      { path: 'admin/card-reference-logs', component: CardReferenceLogsComponent, canActivate: [AuthenticationService] },
      { path: 'admin/audit-trails', component: AuditTrailsComponent, canActivate: [AuthenticationService] },
      //static
      { path: 'verified', component: VerifyComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'expired', component: PageExpiredComponent },
      { path: '404', component: NotFoundComponent },
      { path: '401', component: AccessDeniedComponent },
      { path: '**', redirectTo: '/404' }
    ])
  ],
  declarations: [],
  exports: [ RouterModule]
})
export class AppRoutingModule { }
