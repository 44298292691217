import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'multipleDisplayValue'
})
export class MultipleDisplayValuePipe implements PipeTransform {
  transform(object, list: Array<any>, display: string): string {
    return (_.find(list, object) ? _.find(list, object)[display] : null) || null;
  }
}
