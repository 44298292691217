/* tslint:disable */

declare var Object: any;
export interface PaymentInterface {
  "method": number;
  "orderId": string;
  "paypalId"?: string;
  "reference": string;
  "amount": number;
  "dateCreated": Date;
  "lastUpdated": Date;
  "bankTransactionId"?: number;
  "cardNumber"?: string;
  "orderReference"?: string;
  "id"?: number;
}

export class Payment implements PaymentInterface {
  "method": number;
  "orderId": string;
  "paypalId": string;
  "reference": string;
  "amount": number;
  "dateCreated": Date;
  "lastUpdated": Date;
  "bankTransactionId": number;
  "cardNumber": string;
  "orderReference": string;
  "id": number;
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return "Payment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Payment for dynamic purposes.
  **/
  public static factory(data: PaymentInterface): Payment{
    return new Payment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        "method": {
          name: 'method',
          type: 'number',
          default: 0
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "paypalId": {
          name: 'paypalId',
          type: 'string'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "amount": {
          name: 'amount',
          type: 'number',
          default: 0
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "lastUpdated": {
          name: 'lastUpdated',
          type: 'Date'
        },
        "bankTransactionId": {
          name: 'bankTransactionId',
          type: 'number'
        },
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "orderReference": {
          name: 'orderReference',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
