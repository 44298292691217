import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentApi, RenewalApi } from '../../../../../assets/js/lb-sdk';
import { LoadingAndErrors } from '../../../../classes';
import { ToastrService } from 'ngx-toastr';
import * as PostalCodeValidator from 'postal-codes-js';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import { ProvinceApi, MunicipalityApi } from '../../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { COUNTRIES, COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES } from '../../../../enums';

@Component({
    selector: 'app-renewal',
    templateUrl: './renewal.component.html',
    styleUrls: ['./renewal.component.css']
})
export class RenewalComponent extends LoadingAndErrors implements OnInit {
    COUNTRIES: typeof COUNTRIES = COUNTRIES ;
    COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES: typeof COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES = COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES ;
    bodyClasses = 'skin-blue layout-top-nav';
    body: HTMLBodyElement = document.getElementsByTagName('body')[0];
    params;
    payment;
    renewal;
    state;
    success: boolean = false;
    billingForm: FormGroup;
    disabled: boolean = true;
    //Validation
    emailValid: boolean = true;
    mobileNumberValid: boolean = true;

    provinces = [];
    municipalities = [];
    loading = {
      province: false,
      renewal: true
    };

    constructor(
      private route: ActivatedRoute,
      private paymentApi: PaymentApi,
      private toast: ToastrService,
      private provinceApi: ProvinceApi,
      private municipalityApi: MunicipalityApi,
      private formBuilder: FormBuilder,
      private renewalApi: RenewalApi,
      private router: Router,
      private ModalService: BsModalService
    ) {
      super();
      this.route.paramMap
        .subscribe((params: any) => {
          this.params = params.params.token;
        });
    }

    get f() { return this.billingForm && this.billingForm.controls; }

    createFormControls() {
      this.billingForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        middleName: [],
        lastName: [],
        nameExtension: [''],
        country: ['', Validators.required],
        street: ['', [Validators.required, Validators.maxLength(240)]],
        province: ['', [Validators.required, Validators.maxLength(31)]],
        municipality: ['', [Validators.required, Validators.maxLength(51)]],
        zipCode: [{value: ''}, [Validators.maxLength(10), this.zipCodeValidator()]],
        termsNCondition: ['', [Validators.required, Validators.pattern('true')]],
        mobileNumber: ['', [
          Validators.required,
          (control: FormControl) => {
            if(!this.mobileNumberValid){
              return {
                mismatch: true
              }
            }
            return null
          }
        ]],
        emailAddress: ['', [
          Validators.required,
          Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'),
          (control: FormControl) => {
            if(!this.emailValid){
              return {
                mismatch: true
              }
            }
            return null
          }
        ]]
      })
    }


    ngOnInit() {
        console.log(this.params);
        if (!this.params) {
          this.router.navigate(['/']);
        }
        // add the the body classes
        this.body.classList.remove('sidebar-mini');
        this.body.classList.add('skin-blue');
        this.body.classList.add('layout-top-nav');

        this.createFormControls();
        // this.getProvinces();
        this.getRenewal();
        try {
            if (paypal) {
              paypal.Button.render({
                env: 'production',
                commit: true,
                style: {
                  label: 'paypal',
                  size:  'responsive',
                  // shape: 'rect',
                  color: 'gold',
                  tagline: false
                },
                payment: this.paypalPaymentClick.bind(this),
                onAuthorize: this.paypalPaymentApproved.bind(this)
              }, '#paypal-button');
            }
          } catch (error) {

          }
        // if (!environment.production) {
        //   this.billingForm.patchValue({
        //     firstName: 'Test Firsname',
        //     middleName: 'Test Middle name',
        //     lastName: 'Test Last name',
        //     nameExtension: '',
        //     country: undefined,
        //     street: 'Unit38 Guadix Street',
        //     mobileNumber: '9222222222',
        //     emailAddress: 'wynonah@sapsenseconsulting.com'
        //   });
        // }

        this.countryOnChange();
    }

    zipCodeValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        let countryCode;
        if (!this.f || !this.f.country || !this.f.country.value) {
          return null;
        } else {
          countryCode = COUNTRIES[this.f.country.value];
          if (!control.value) {
            if (COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES.indexOf(countryCode) < 0) {
              return { 'required': { value: control.value } };
            } else {
              return null;
            }
          }
        }
        let invalid = false;
        switch (countryCode) {
          case 'AR':
            if (control.value) {
              const regex = new RegExp('^[a-hj-np-zA-HJ-NP-Z]{1}[0-9]{4}[a-zA-Z]{3}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'BO':
            if (control.value) {
              const regex = new RegExp('^[0-9]{6,10}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'CA':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'CN':
            if (control.value) {
              const regex = new RegExp('^[0-9]{3,4}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'FO':
            if (control.value) {
              const regex = new RegExp('^[fF][oO][0-9]{3}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'GG':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z]{2}[0-9]{1,2} [0-9]{1}[a-zA-Z]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'HN':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z]{2}[0-9]{4}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'IM':
            if (control.value) {
              const regex = new RegExp('^[iI][mM][0-9]{1,2} [0-9]{1}[a-zA-Z]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'JE':
            if (control.value) {
              const regex = new RegExp('^[jJ][eE][0-9]{1,2} [0-9]{1}[a-zA-Z]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'LB':
            if (control.value) {
              const regex = new RegExp('^[0-9]{4}(-[0-9]{4}){0,1}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'MQ':
            if (control.value) {
              const regex = new RegExp('^972[0-9]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'MT':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z]{3} [0-9]{4}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'NL':
            if (control.value) {
              const regex = new RegExp('^[0-9]{4} [a-zA-Z]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'PA':
            if (control.value) {
              const regex = new RegExp('^0[0-9]{3} [0-9]{1}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'PL':
            if (control.value) {
              const regex = new RegExp('^[0-9]{2}-[0-9]{3}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'PS':
            if (control.value) {
              const regex = new RegExp('^[0-9]{3}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'PT':
            if (control.value) {
              const regex = new RegExp('^[0-9]{4}-[0-9]{3}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'RS':
            if (control.value) {
              const regex = new RegExp('^[0-9]{5}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'RS':
          case 'SA':
            if (control.value) {
              const regex = new RegExp('^[0-9]{5}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'SO':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z]{2} [0-9]{5}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'UM':
            if (control.value) {
              const regex = new RegExp('96898');
              invalid = !regex.test(control.value);
            }
            break;
          case 'US':
            if (control.value) {
              const regex = new RegExp('[0-9]{5}-[0-9]{4}');
              invalid = !regex.test(control.value);
            }
            break;
          case 'VA':
            if (control.value) {
              const regex = new RegExp('120');
              invalid = !regex.test(control.value);
            }
            break;
          case 'CZ':
          case 'GR':
          case 'JP':
          case 'SE':
          case 'SK':
            if (control.value) {
              const regex = new RegExp('^[0-9]{3} [0-9]{2}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'IR':
            if (control.value) {
              const regex = new RegExp('^[0-9]{5}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'KP':
            if (control.value) {
              invalid = control.value.length <= 7;
            }
            break;
          case 'SI':
            if (control.value) {
              const regex = new RegExp('^[0-9]{4}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'VE':
            if (control.value) {
              const regex = new RegExp('^[0-9]{4}$');
              invalid = !regex.test(control.value);
            }
            break;
          case 'EH':
            if (control.value) {
              invalid = control.value.length <= 10;
            }
            break;
          case 'BQ':
          case 'BV':
          case 'CW':
          case 'IE':
          case 'SX':
            if (control.value) {
              const regex = new RegExp('^[a-zA-Z0-9]{1,10}$');
              invalid = !regex.test(control.value);
            }
            break;
          default:
            invalid = PostalCodeValidator.validate(countryCode, control.value) !== true;
            break;
        }
        return invalid ? { 'invalid': { value: control.value } } : null;
      };
    }

    parseZipCode(country, value) {
      const spaceIsImportant = ['AQ', 'AZ', 'IO', 'CA', 'CZ', 'FK', 'GI', 'GR', 'GG', 'IM', 'JE', 'MT', 'NL', 'PN', 'GS', 'SH', 'SK', 'SO', 'LC', 'SE', 'TC', 'GB'];
      const hypenIsImportant = ['AI', 'BR', 'KY', 'JP', 'LV', 'LT', 'PA', 'PL', 'PT', 'US', 'GU','LB', 'MH', 'FM', 'MD', 'MP', 'PW', 'PR'];
      if (spaceIsImportant.indexOf(country) < 0) {
        value = value.split(' ').join('');
      }
      if (hypenIsImportant.indexOf(country) < 0) {
        value = value.split('-').join('');
      }
      return value;
    }

    getRenewal() {
      this.loading.renewal = true;
      this.renewalApi
        .fetchByToken(this.params)
        .pipe(finalize(() => {
          this.loading.renewal = false;
        }))
        .subscribe(
          data => {
            console.log('renewal  : ', data);
            this.loading.renewal = false;
            this.renewal = data.response;
          },
          error => {
            console.log('error : ', error.message);
            if (error.message.indexOf('anymore') != -1) {
              this.router.navigate(['/renewal/' + this.params + '/expired']);
            } else if (error.message.indexOf('Not yet allowed to renew.') != -1) {
              this.router.navigate(['/expired']);
            } else {
              this.router.navigate(['/']);
            }
          }
        );
    }

    paypalPaymentClick() {
      this.errors.payment = undefined;
      console.log('this.billingForm : ', this.billingForm.valid);
      return this.paymentApi
        .buildPaypalObject({
          key: 'token',
          value: this.params
        })
        .toPromise()
        .then(data => {
          console.log('data  : ', data);
          this.payment = data;
          return data && data.id;
        })
        .catch(error => {
          this.errors.payment = error && error.message ||
            (this.state.params.id ? 'Failed to update payment.' : 'Failed to place order.');
        });
    }

    countryOnChange() {
      if (this.f.country.value) {
        this.f.street.enable();
        this.f.province.enable();
        this.f.municipality.enable();
        this.f.zipCode.enable();
      } else {
        this.f.street.disable();
        this.f.street.setValue(undefined);
        this.f.province.disable();
        this.f.province.setValue(undefined);
        this.f.municipality.disable();
        this.f.municipality.setValue(undefined);
        this.f.zipCode.disable();
        this.f.zipCode.setValue(undefined);
      }
    }

    paypalPaymentApproved(data) {
      console.log('APPROVED');
      this.errors.payment = undefined;
      const billingDetails = {
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        lastName: this.f.lastName.value,
        nameExtension: this.f.nameExtension.value,
        country: this.f.country.value,
        countryCode: COUNTRIES[this.f.country.value],
        street: this.f.street.value,
        province: this.f.province.value,
        municipality: this.f.municipality.value,
        zipCode: this.parseZipCode(COUNTRIES[this.f.country.value], this.f.zipCode.value),
        mobileNumber: this.f.mobileNumber.value,
        emailAddress: this.f.emailAddress.value,
      }
      console.log("data :", data);
      return this.paymentApi.
        buildPayPalOrder({
          paymentId: data.paymentID,
          payerId: data.payerID,
          primary: {
            key: 'token',
            value: this.params
          },
          details : billingDetails
        })
        .toPromise()
        .then(response => {
          console.log(response);
          this.toast.success('Order successfully placed', 'Place Order');
          this.success = true;
          this.router.navigate(
            ['/renewal/' + this.params + '/order/' + response.id],
            {
              queryParams: {
                card: response._orderItems[0].planType || 'S',
                reference: response.reference || null
              }
            }
          );
        })
        .catch(error => {
          this.errors.payment = error && error.message || 'Failed to place order.';
        });
    }

    // On change
    provinceOnChange() {
      console.log(this.f.province.value);
      const province = this.f.province.value;
      this.billingForm.patchValue({
        municipality: null
      });

      this.municipalityApi.find({ where: { provinceId: province.key } })
      .subscribe(
        response => {
          this.municipalities = response;
        },
        error => {
          console.log('ERROR: ', error.code);
        }
      );
    }

    municipalityOnChange() {
      const municipality = this.f .municipality.value;

      const zipcode = this.municipalities.filter(arr => {
        return arr.id === municipality.id;
      });

      this.billingForm.patchValue({
        zipCode: zipcode[0].zipcode
      });
    }

    // APIS
    getProvinces() {
      this.loading.province = true;
      this.provinceApi.find()
      .subscribe(
        response => {
          this.loading.province = false;
          this.provinces = response;
        },
        error => {
          this.loading.province = false;
          // this.getProvinces();
          console.log('ERROR: ', error.code);
        }
      );
    }


  termsNCondition() {
    const termsAndConditionModal = this.ModalService
      .show(TermsAndConditionComponent, {
        initialState: {
          // data: { url: 'google.com' }
        }
      });
    const subscription = this.ModalService.onHidden
      .subscribe(() => {
        if (termsAndConditionModal.content) {
          if (termsAndConditionModal.content.accept) {
            this.billingForm.patchValue({
              termsNCondition: true
            });
          }
        }
        subscription.unsubscribe();
      });
  }

    ngOnDestroy() {}
}

@Component({
  selector: 'app-renewal-terms-and-condition',
  templateUrl: './terms-and-condition.html',
  styleUrls: ['./renewal.component.css']
})

export class TermsAndConditionComponent implements OnInit {
  constructor(
    public modal: BsModalRef
  )  {

  }
  ngOnInit() {

  }

  accept() {
    this.modal.content.accept = true;
    this.modal.hide();
  }

  dismiss(reason: string) {
    this.modal.content.accept = false;
    this.modal.hide();
  }
}
