import { NgModule, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { UserDataApi, LoopBackAuth } from '../../../../../assets/js/lb-sdk';

interface ChangeUsernameForm {
  id?: number,
  username?: string,
  previousUsername?: string,
  usernameUsed?: boolean,
};

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.css']
})
export class ChangeUsernameComponent implements OnInit {
  REGEX = {
    USERNAME: /^[a-zA-Z]+[a-zA-Z0-9_]*$/
  };
  data: ChangeUsernameForm;
  error: string;
  loading: boolean = false;

  constructor(
    public modal: BsModalRef,
    private userDataApi: UserDataApi,
    private loopBackAuth: LoopBackAuth
  ) { }

  ngOnInit() {
    this.data = this.data ? {
      ...this.data,
      previousUsername: this.data.username
    } : {};
  }

  dismiss(reason: string) {
    this.modal.content.reason = reason;
    this.modal.hide();
  }

  close(form) {
    this.error = undefined;
    if (form.valid) {
      this.loading = true;
      this.userDataApi
        .matchUsername(this.data.username)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          hasMatch => {
            this.data.usernameUsed = hasMatch.response;
            if (this.data.usernameUsed) {
              if (this.data.username.toLowerCase() === this.getUser().username.toLowerCase()) {
                this.error = 'New username is same as current username';
              } else {
                this.error = 'Username already used';
              }
            } else {
              this.loading = true;
              this.userDataApi
                .patchAttributes(this.getUser().id, {
                  username: this.data.username
                })
                .pipe(finalize(
                  () => {
                    this.loading = false;
                  }
                ))
                .subscribe(
                  () => {
                    if (!this.data.id) {
                      this.userDataApi
                        .findById(this.getUser().id, { include: { roles: 'role' } })
                        .subscribe(user => {
                          this.loopBackAuth.setUser(user);
                        });
                    }
                    this.modal.content.result = 'done';
                    this.modal.hide();
                  },
                  error => {
                    this.error = 'Failed to update username';
                  }
                )
            }
          },
          error => {
            this.error = 'Failed to check availability of username';
          }
        );
      }
  }

  getUser() {
    if (this.data.id) {
      return {
        id: this.data.id,
        username: this.data.previousUsername
      };
    } else {
      return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }
  }

  onUsernameChange() {
    this.data.usernameUsed = false;
  }
}
