import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, toReplace: string, replaceWith: string): string {
    return (value || '').split(toReplace).join(replaceWith);
  }

}
