import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { LoopBackAuth, BranchApi, ProductApi } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-branch-form',
    templateUrl: './branch-form.component.html',
    styleUrls: ['./branch-form.component.css']
})
export class BranchFormComponent implements OnInit {
    editBranchForm: FormGroup;
    data?;
    name: FormControl;
    location: FormControl;
    consultation: FormControl;
    laboratory: FormControl;

    loading: boolean = false;

    form: any = {};

    products: any;

    constructor(
        private modal: BsModalRef,
        private toast: ToastrService,
        private branchApi: BranchApi,
        private productApi: ProductApi
    ) {
        this.name = new FormControl('', [Validators.required, Validators.minLength(1)]);
        this.location = new FormControl('', [Validators.required, Validators.minLength(1)]);
        this.consultation = new FormControl('', [Validators.required, Validators.minLength(1)]);
        this.laboratory = new FormControl('', [Validators.required, Validators.minLength(1)]);

        this.editBranchForm = new FormGroup({
            name: this.name,
            location: this.location,
            consultation: this.consultation,
            laboratory: this.laboratory
        });
    }

    ngOnInit() {
        this.data = this.data || {};
        console.log(this.data);

        this.name.setValue(this.data.name, { onlySelf: true });
        this.location.setValue(this.data.location, { onlySelf: true });
        // this.consultation.setValue(this.data.outpatient_consultation, { onlySelf: true });
        // this.laboratory.setValue(this.data.outpatient_laboratory, { onlySelf: true });

        this.fetchProducts();
    }

    isLoading() {
        return this.loading;
    }

    dismiss(reason: string) {
        this.modal.content.reason = reason;
        this.modal.hide();
    }

    @Output() action = new EventEmitter();
    submit() {
        this.loading = true;

        let consultation_products = [];
        for(var i in this.consultation.value) {
            consultation_products.push(this.consultation.value[i]['productType'] +"_"+ this.consultation.value[i]['planType']);
        }

        let laboratory_products = [];
        for(var i in this.laboratory.value) {
            laboratory_products.push(this.laboratory.value[i]['productType'] +"_"+ this.laboratory.value[i]['planType']);
        }

        var obj = {
            id: this.data.id,
            name: this.name.value,
            location: this.location.value,
            outpatient_consultation: consultation_products.join(','),
            outpatient_laboratory: laboratory_products.join(','),
            archived: this.data.archived
        }
        // console.log(this.consultation.value);
        // console.log(this.laboratory.value);
        // console.log(obj);

        this.branchApi.upsert(obj)
        .subscribe(
            response => {
                this.loading = false;
                this.toast.success(this.name.value + ' was successfully updated.', 'Success!');
                console.log(response);

                this.action.emit(response);
                this.modal.hide();
            },
            error => {
                this.loading = false;
                this.toast.error(error.message, 'Error!');
                // console.log('ERROR: ', error.code);
            }
        );
    }

    checkForm() {
        let valid = true;
        if(this.name.value.replace(/\s/g) == "") {
            valid = false;
        }
        if(this.location.value == "") {
            valid = false;
        }
        return valid;
    }

    fetchProducts() {
        this.productApi.find()
        .subscribe(
            response => {
                this.products = response;

                const consultation = this.data.outpatient_consultation.split(',');
                let selectedConsultation=[];
                for(const i in consultation) {
                    const consultation_arr = consultation[i].split('_');
                    const res = response.filter(product => product['productType'] == consultation_arr[0] && product['planType'] == consultation_arr[1]);
                    
                    for(const j in res) {
                        selectedConsultation.push(res[j]);
                    }
                }

                this.consultation.setValue(selectedConsultation, { onlySelf: true });
                
                const laboratory = this.data.outpatient_laboratory.split(',');
                let selectedlaboratory=[];
                for(const i in laboratory) {
                    const laboratory_arr = laboratory[i].split('_');
                    const res = response.filter(product => product['productType'] == laboratory_arr[0] && product['planType'] == laboratory_arr[1]);
                    
                    for(const j in res) {
                        selectedlaboratory.push(res[j]);
                    }
                }

                this.laboratory.setValue(selectedlaboratory, { onlySelf: true });

            },
            error => {
                console.log(error);
            }
        );
    }

}
