import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

interface ModalData {
  cardNumber: string;
  policyNumber: string;
  referenceNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nameExtension: string;
  birthDate: string;
  gender: string;
  civilStatus: string;
  street: string;
  provinceName: string;
  municipalityName: string;
  zipCode: string;
  mobileNumber: string;
  emailAddress: string;
}

@Component({
  selector: 'app-modal-registration-summary',
  templateUrl: './modal-registration-summary.component.html',
  styleUrls: ['./modal-registration-summary.component.css']
})
export class ModalRegistrationContentComponent implements OnInit {
  @Output() onButtonClick: EventEmitter<string> = new EventEmitter();
  @Output() onEditClick: EventEmitter<number> = new EventEmitter();

  isCheckboxChecked = false;
  isButtonEnabled = false;

  name: string;
  cardNumber: string;
  policyNumber: string;
  referenceNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nameExtension: string;
  birthDate: string;
  gender: string;
  civilStatus: string;
  street: string;
  province: string;
  municipality: string;
  zipCode: string;
  mobileNumber: string;
  emailAddress: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    const initialState: ModalData = this.bsModalRef.content;
    if (initialState) {
      this.cardNumber = initialState.cardNumber;
      this.policyNumber = initialState.policyNumber;
      this.referenceNumber = initialState.referenceNumber;
      this.firstName = initialState.firstName;
      this.middleName = initialState.middleName;
      this.lastName = initialState.lastName;
      this.nameExtension = initialState.nameExtension;
      this.birthDate = initialState.birthDate;
      this.gender = initialState.gender;
      this.civilStatus = initialState.civilStatus;
      this.street = initialState.street;
      this.province = initialState.provinceName;
      this.municipality = initialState.municipalityName;
      this.zipCode = initialState.zipCode;
      this.mobileNumber = initialState.mobileNumber;
      this.emailAddress = initialState.emailAddress;
    }
  }

  handleCancel() {
    this.onButtonClick.emit('cancel');
    this.bsModalRef.hide();
  }

  handleProceed() {
    this.onButtonClick.emit('proceed');
    this.bsModalRef.hide();
  }

  handleCheckboxChange(event: any) {
    this.isCheckboxChecked = event.target.checked;
    this.isButtonEnabled = this.isCheckboxChecked;
  }

  editOnStep(e: MouseEvent, n: number) {
    e.preventDefault();
    e.stopPropagation();
    console.log('selected Step', n);
    this.onEditClick.emit(n);
    this.bsModalRef.hide();
  }
}

