import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    bodyClasses = 'skin-blue layout-top-nav';
    body: HTMLBodyElement = document.getElementsByTagName('body')[0];

    constructor() { }

    ngOnInit() {
        // add the the body classes
        this.body.classList.remove('sidebar-mini');
        this.body.classList.add('skin-blue');
        this.body.classList.add('layout-top-nav');
    }

    ngOnDestroy() {
        // remove the the body classes
        // this.body.classList.remove('skin-blue');
        // this.body.classList.remove('layout-top-nav');
    }
}
