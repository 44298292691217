/* tslint:disable */
import { Injectable } from '@angular/core';
import { RoleData } from '../../models/RoleData';
import { UserData } from '../../models/UserData';
import { UserRole } from '../../models/UserRole';
import { CustomerInfo } from '../../models/CustomerInfo';
import { CustomerCard } from '../../models/CustomerCard';
import { Gender } from '../../models/Gender';
import { CivilStatus } from '../../models/CivilStatus';
import { Province } from '../../models/Province';
import { Municipality } from '../../models/Municipality';
import { Email } from '../../models/Email';
import { EmailTemplate } from '../../models/EmailTemplate';
import { Sms } from '../../models/Sms';
import { Configuration } from '../../models/Configuration';
import { SMSConfiguration } from '../../models/SMSConfiguration';
import { Product } from '../../models/Product';
import { CronJob } from '../../models/CronJob';
import { External } from '../../models/External';
import { StorageManager } from '../../models/StorageManager';
import { Otp } from '../../models/Otp';
import { Branch } from '../../models/Branch';
import { Member } from '../../models/Member';
import { EmailLog } from '../../models/EmailLog';
import { Renewal } from '../../models/Renewal';
import { Payment } from '../../models/Payment';
import { Order } from '../../models/Order';
import { OrderItem } from '../../models/OrderItem';
import { Or } from '../../models/Or';
import { GenerateCardReferenceLog } from '../../models/GenerateCardReferenceLog';
import { AuditTrail } from '../../models/AuditTrail';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    RoleData: RoleData,
    UserData: UserData,
    UserRole: UserRole,
    CustomerInfo: CustomerInfo,
    CustomerCard: CustomerCard,
    Gender: Gender,
    CivilStatus: CivilStatus,
    Province: Province,
    Municipality: Municipality,
    Email: Email,
    EmailTemplate: EmailTemplate,
    Sms: Sms,
    Configuration: Configuration,
    SMSConfiguration: SMSConfiguration,
    Product: Product,
    CronJob: CronJob,
    External: External,
    StorageManager: StorageManager,
    Otp: Otp,
    Branch: Branch,
    Member: Member,
    EmailLog: EmailLog,
    Renewal: Renewal,
    Payment: Payment,
    Order: Order,
    OrderItem: OrderItem,
    Or: Or,
    GenerateCardReferenceLog: GenerateCardReferenceLog,
    AuditTrail: AuditTrail,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
