import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs/Subject';

import * as $ from "jquery";

import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { LoopBackAuth, EmailTemplateApi } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit {
    emailTemplateForm: FormGroup;
    data?;
    name: FormControl;
    description: FormControl;
    template: FormControl;

    loading: boolean = false;

    form: any = {};

    summernoteConfig = {
        placeholder: '',
        tabsize: 2,
        height: 500,
        toolbar: [
            // [groupName, [list of button]]
            ['misc', ['codeview', 'undo', 'redo']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontsize', ['fontname', 'fontsize', 'color']],
            ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['table', 'picture', 'link', 'video', 'hr']],
            ['custom', ['variables']]
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
        oninit: function() {
            // Add "open" - "save" buttons
            setTimeout(() => {
                var noteBtn = '<button id="makeSnote" type="button" class="btn btn-default btn-sm btn-small" title="Add Code" data-event="something" tabindex="-1"><i class="fa fa-file-text"></i></button>';
                var fileGroup = '<div class="note-file btn-group">' + noteBtn + '</div>';
                $(fileGroup).appendTo($('.note-toolbar'));
            }, 1000);
            // Button tooltips
            // $('#makeSnote').tooltip({
            //     container: 'body',
            //     placement: 'bottom'
            // });
            // Button events
            // $('#makeSnote').click(function(event) {
            //     var range = window.getSelection().getRangeAt(0);
            //     var node = $(range.commonAncestorContainer)
            //     if (node.parent().is('code')) {
            //         node.unwrap();
            //     } else {
            //         node = $('<code />')[0];
            //         range.surroundContents(node);
            //     };
            //
            // });
        }
    }

    constructor(
        private modal: BsModalRef,
        private sanitizer: DomSanitizer,
        private toast: ToastrService,
        private emailTemplate: EmailTemplateApi
    ) {
        this.name = new FormControl({ values: '', disabled: true }, [Validators.required, Validators.minLength(1)]);
        this.description = new FormControl('', [Validators.required, Validators.minLength(1)]);
        this.template = new FormControl('', Validators.required);


        this.emailTemplateForm = new FormGroup({
            name: this.name,
            description: this.description,
            template: this.template
        });
    }

    ngOnInit() {
        this.data = this.data || {};
        console.log(this.data);

        this.name.setValue(this.data.name, { onlySelf: true });
        this.description.setValue(this.data.description, { onlySelf: true });
        // console.log('HTML: ', this.data.template);
        this.template.setValue(this.data.template.toString(), { onlySelf: true }); //#notsanitized this.sanitizeHtml(this.data.template);
    }

    isLoading() {
        return this.loading;
    }

    dismiss(reason: string) {
        this.modal.content.reason = reason;
        this.modal.hide();
    }

    @Output() action = new EventEmitter();
    submit() {
        this.loading = true;
        var obj = {
            id: this.data.id,
            name: this.name.value,
            description: this.description.value,
            template: this.template.value,
            archived: this.data.archived
        }

        this.emailTemplate.upsert(obj)
        .subscribe(
            response => {
                this.loading = false;
                this.toast.success(this.name.value + ' was successfully updated.', 'Success!');
                console.log(response);

                this.action.emit(response);
                this.modal.hide();
            },
            error => {
                this.loading = false;
                this.toast.error(error.message, 'Error!');
                // console.log('ERROR: ', error.code);
            }
        );
    }

    checkForm() {
        let valid = true;
        if(this.name.value.replace(/\s/g) == "") {
            valid = false;
        }
        if(this.template.value == "") {
            valid = false;
        }
        return valid;
    }

    sanitizeHtml(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

}
