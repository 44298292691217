import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromEnum'
})
export class FromEnumPipe implements PipeTransform {

  transform(enumeration: Object, isString?: boolean): Array<string> {
    const keys = Object.keys(enumeration || {});
    return isString ? keys : keys.slice(keys.length / 2);
  }

}

