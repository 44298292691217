import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '../../interfaces/Pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() pagination: Pagination = {
    page: 1,
    total: 0,
    limit: 10
  };
  @Output() pageChange = new EventEmitter<Pagination>();
  limitOptions: Array<number> = [10, 25, 50, 100];

  constructor() { }

  ngOnInit() {
  }

  getLastPage():number {
    return Math.ceil(this.pagination.total/this.pagination.limit) || 1;
  }

  goToPage(page) {
    this.pagination.page = page;
    this.pageChange.emit(this.pagination);
  }

  getPages():Array<number> {
    let start: number = this.pagination.page - 2 > 0 ? this.pagination.page - 2 : 1;
    let end: number = start + 4 > Math.ceil(this.pagination.total/this.pagination.limit) ? Math.ceil(this.pagination.total/this.pagination.limit) || 1 : start + 4;
    start = end - 4 > 0 ? end - 4 : 1;
    let array: Array<number> = [];
    for (let index: number = start; index <= end; index++) {
      array.push(index);
    }
    return array;
  }
}
