import { Component } from '@angular/core';
import { BasicList, Register } from '../../../classes';
import { ProductApi, ExternalApi, LoopBackConfig, CustomerCardApi } from '../../../../assets/js/lb-sdk';
import { BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { USER_ROLES } from '../../../enums';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import * as moment from 'moment'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DisplayValuePipe } from '../../../pipes/display-value.pipe';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { MultipleDisplayValuePipe } from '../../../pipes/multiple-display-value.pipe';
import { SapDatetimePipe } from '../../../pipes/datetime.pipe';
import { ExpiryDatetimePipe } from '../../../pipes/expiry.pipe';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent extends BasicList {
  params = {};
  USER_ROLES: typeof USER_ROLES = USER_ROLES;
  host:string = LoopBackConfig.getPath();
  // body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  filterForm: FormGroup;

  sources = [
    { name: 'Sales Viewpoint', value: "1" },
    { name: 'Online Store', value: "2" },
    { name: 'Preprinted', value: "3" },
    { name: 'Member Viewpoint', value: "4" }
  ]
  statuses = [
    { name: 'Active', value: "1" },
    { name: 'Inactive', value: "2" },
    { name: 'Renewed', value: "3" },
    { name: 'Expired', value: "4" }
  ]
  products: any=[]

  moment = moment

  orderId = "";
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private externalApi: ExternalApi,
    private ModalService: BsModalService,
    private loopBackAuth: LoopBackAuth,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private productApi: ProductApi,
    private displayValue: DisplayValuePipe,
    private customerCardApi:CustomerCardApi,
    private multipleDisplayValuePipe: MultipleDisplayValuePipe,
    private sapDatetimePipe: SapDatetimePipe,
    private expiryDatetimePipe: ExpiryDatetimePipe
  ) {
    super();
    // this.body.classList.add('skin-blue');
    // this.body.classList.add('layout-top-nav');
    // this.body.classList.add('sidebar-mini');
   
    // this.maxDate.setDate(this.maxDate.getDate() + 7); //Weekly report
    this.bsRangeValue = [this.bsValue, this.bsValue];
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });

    this.filterForm = this.formBuilder.group({
      source: [],
      product: [],
      selected: [],
      registerRangeValue: [],
      purchaseRangeValue: [],
      orderNumber: [],
      status: [],
      expiryRangeValue: []
    })
    this.settings = {
      api: externalApi,
      ModalService: ModalService,
      service: 'getReports',
      isExternal: true
    };
    this.loading.product = true;
    productApi
      .find()
      .pipe(finalize(() => {
        this.loading.product = false;
      }))
      .subscribe(
        (response: any) => {
          this.products = response;
        },
        error => {
          this.errors.products = error.message;
        }
      );
  }

  ngOnDestroy() {
    // this.body.classList.remove('sidebar-mini');
  }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

  filter() {
    this.parseParams();
    this.getList();
  }

  onChangeFilter() {
    this.pagination = {
      page: 1,
      limit: 10,
      total: 0
    }
  }

  parseParams() {
    this.f.source.value || this.f.source.value === 'null' ? this.params['Source'] = this.f.source.value : delete this.params['Source'];
    if (this.f.product.value && this.f.product.value !== "") {
      this.params['ProductType'] = this.displayValue.transform(this.f.product.value, this.products, "id", "productType");
      this.params['PlanType'] = this.displayValue.transform(this.f.product.value, this.products, 'id', 'planType');
    } else {
      delete this.params['ProductType'];
      delete this.params['PlanType'];
    }
    if (this.f.registerRangeValue.value || this.f.registerRangeValue.value === 'null') {
      if (moment(this.f.registerRangeValue.value[0]).format('YYYYMMDD') === moment(this.f.registerRangeValue.value[1]).format('YYYYMMDD')) {
        this.params['DateRegistered'] = moment(this.f.registerRangeValue.value[0]).format('YYYYMMDD');
      } else {
        this.params['DateRegistered'] = [
          moment(this.f.registerRangeValue.value[0]).format('YYYYMMDD'),
          moment(this.f.registerRangeValue.value[1]).format('YYYYMMDD')
        ];
      }
    } else {
      delete this.params['DateRegistered'];
    }
    if (this.f.expiryRangeValue.value || this.f.expiryRangeValue.value === 'null') {
      if (moment(this.f.expiryRangeValue.value[0]).format('YYYYMMDD') === moment(this.f.expiryRangeValue.value[1]).format('YYYYMMDD')) {
        this.params['DateRegistered'] = moment(this.f.expiryRangeValue.value[0]).subtract(1, 'year').add(1, 'day').format('YYYYMMDD');
      } else {
        this.params['DateRegistered'] = [
          moment(this.f.expiryRangeValue.value[0]).subtract(1, 'year').add(1, 'day').format('YYYYMMDD'),
          moment(this.f.expiryRangeValue.value[1]).subtract(1, 'year').add(1, 'day').format('YYYYMMDD')
        ];
      }
    } else if (!this.f.registerRangeValue.value) {
      delete this.params['DateRegistered'];
    }
    this.f.status.value || this.f.status.value === 'null' ? this.params['Status'] = this.f.status.value : delete this.params['Status'];
    this.f.orderNumber.value || this.f.orderNumber.value === 'null' ? this.params['OrderId'] = this.f.orderNumber.value : delete this.params['OrderId'];
    if (this.f.purchaseRangeValue.value || this.f.purchaseRangeValue.value === 'null') {
      if (moment(this.f.purchaseRangeValue.value[0]).format('YYYYMMDD') === moment(this.f.purchaseRangeValue.value[1]).format('YYYYMMDD')) {
          this.params['DateGenerated'] = moment(this.f.purchaseRangeValue.value[0]).format('YYYYMMDD');
      } else {
        this.params['DateGenerated'] = [
          moment(this.f.purchaseRangeValue.value[0]).format('YYYYMMDD'),
          moment(this.f.purchaseRangeValue.value[1]).format('YYYYMMDD')
        ];
      }
    } else {
      delete this.params['DateGenerated'];
    }
  }

  get f() { return this.filterForm.controls; }

  submit() {
    // TODO
  }

  // resendReferenceNumber(data) {
  //   const conf = confirm("Are you sure you want to resend this reference number?");
  //   if(conf) {
  //     console.log(data);
  //     this.loading.reactivate = true;
  //     this.customerCardApi
  //       .resendReferenceNumber({ referenceNumber : data.ReferenceNumber })
  //       .pipe(finalize(() => {
  //         this.loading.reactivate = false;
  //       }))
  //       .subscribe(
  //         data => {
  //           console.log('data', data);
  //           this.toast.success('Success!', 'Reference Number was sent to ' + data.response.customer_email);
  //         },
  //         error => {
  //           console.log('error', error);
  //           this.toast.error('ERROR!', 'Sending Reference Number failed.');
  //         }
  //       );
  //   }
  // }
  resendReferenceNumber() {
    console.log(this.orderId);
  }

  resendVoucher(data) {
    const conf = confirm("Are you sure you want to resend the voucher of this reference number?");
    if(conf) {
      this.loading.reactivate = true;
      this.customerCardApi
        .resendVoucher({ referenceNumber : data.ReferenceNumber })
        .pipe(finalize(() => {
          this.loading.reactivate = false;
        }))
        .subscribe(
          data => {
            this.toast.success('Success!', 'Reference Number was sent to ' + data.response.customer_email);
          },
          error => {
            this.toast.error('ERROR!', 'Sending Reference Number to failed.');
          }
        );
    }
  }

  csv() {
    this.parseParams();
    this.getListForExport((data) => {
      let list = [];
      for(const i in data) {
        list.push({
          Source: this.displayValue.transform(data[i].Source, this.sources, 'value', 'name'),
          ReferenceNumber: data[i].ReferenceNumber,
          OrderNumber: data[i].OrderId,
          PurchaseDate: data[i].DateGenerated !== "" ?  moment(data[i].DateGenerated, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/a',
          ProductPurchased: this.multipleDisplayValuePipe.transform({ productType: data[i].ProductType, planType: data[i].PlanType }, this.products, 'name'),
          RegisterStatus: (this.sapDatetimePipe.transform(data[i].DateRegistered) && data.Source === '4') ? "Renewed" : this.sapDatetimePipe.transform(data[i].DateRegistered) ? "Active" : "Inactive",
          RegisterDate: data[i].DateRegistered !== "" ?  moment(data[i].DateRegistered, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/a'
        });
      }
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        headers: ["Source", "Reference Number", "Order Number", "Purchase Date", "Product Purchase", "Register Status", "Register Date", "Expiry Date"]
      };
      new Angular5Csv(list, 'Sales Reports', options);
    });
  }

  clear() {
    this.params = {};
    this.filterForm.patchValue({
      source: null,
      product: null,
      selected: null,
      registerRangeValue: null,
      purchaseRangeValue: null,
      expiryRangeValue: null,
      orderNumber: null,
      status: null
    });
    this.getList();
  }

  isExpired(date) {
    return moment(this.expiryDatetimePipe.transform(date), 'MM-DD-YYYY') < moment().startOf('day')
  }

  removeRegisterDate(event) {
    console.log('register called');
    this.filterForm.patchValue({
      registerRangeValue: null,
    });
  }

  removeExpiryDate(event) {
    console.log('expiry called');
    this.filterForm.patchValue({
      expiryRangeValue: null,
    });
  }

}
