import { OnInit } from '@angular/core';
import { PartnerForm } from '../interfaces';

interface Pager {
  partner: boolean;
  validate: boolean;
  account: boolean;
  review: boolean;
}

interface Loader {
  partnerIdVerification: boolean;
  partnerVerification: boolean;
  credentialsValidation: boolean;
  accountCreation: boolean;
  partnerDetails: boolean;
}

export class Register implements OnInit {
  partner:PartnerForm = {
    created: false
  };
  error: string;
  page:Pager = {
    partner: true,
    validate: false,
    account: false,
    review: false
  };
  loader:Loader = {
    partnerVerification: false,
    partnerIdVerification: false,
    credentialsValidation: false,
    accountCreation: false,
    partnerDetails: false
  };
  REGEX = {
    USERNAME: /^[a-zA-Z]+[a-zA-Z0-9_]*$/,
    USERCODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)])[A-Za-z\d$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)]{8,}$/
  };

  ngOnInit() { }

  verifyPartner($event: { event:string, data:any }) {
    switch ($event.event) {
      case 'started':
        this.loader.partnerIdVerification = true;
        this.error = undefined;
        break;
      case 'success':
        this.partner.type = $event.data.exists;
        this.next('validate');
        break;
      case 'failed':
        this.error = $event.data;
        break;
      case 'ended':
        this.loader.partnerIdVerification = false;
        break;
    }
  }

  verifyIdentity($event) {
    switch ($event.event) {
      case 'started':
        this.loader.partnerVerification = true;
        this.error = undefined;
        break;
      case 'success':
        this.next('account');
        break;
      case 'failed':
        this.error = $event.data;
        break;
      case 'ended':
        this.loader.partnerVerification = false;
        break;
    }
  }

  setCredentials($event) {
    switch ($event.event) {
      case 'started':
        this.loader.credentialsValidation = true;
        this.error = undefined;
        break;
      case 'success':
        this.next('review');
        break;
      case 'failed':
        this.error = $event.data;
        break;
      case 'ended':
        this.loader.credentialsValidation = false;
        break;
    }
  }

  register($event) {
    switch ($event.event) {
      case 'started':
        this.loader.accountCreation = true;
        this.error = undefined;
        break;
      case 'success':
        this.partner.created = true;
        break;
      case 'failed':
        this.error = $event.data;
        break;
      case 'ended':
        this.loader.accountCreation = false;
        break;
    }
  }

  next(to: string) {
    for (const i of Object.keys(this.page)) {
      this.page[i] = false;
    }
    this.page[to] = true;
  }

  isLoading():boolean {
    return Object.keys(this.loader).reduce((loading, key):boolean => {
      return loading || this.loader[key];
    }, false);
  }
}
