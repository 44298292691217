import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup
} from '@angular/forms';
@Component({
    selector: 'app-renewal-manual',
    templateUrl: './manual-renewal.component.html',
    styleUrls: ['./manual-renewal.component.css']
})
export class ManualRenewalComponent implements OnInit {
    bodyClasses = 'skin-blue layout-top-nav';
    body: HTMLBodyElement = document.getElementsByTagName('body')[0];
    tab = 1;
  	renewalForm: FormGroup;
    cardNumber: FormControl;
    referenceNumber: FormControl;
  
    constructor() { }

    ngOnInit() {
        // add the the body classes
        this.body.classList.remove('sidebar-mini');
        this.body.classList.add('skin-blue');
        this.body.classList.add('layout-top-nav');
        this.cardNumber = new FormControl('', [Validators.required, Validators.minLength(16)]);
        this.referenceNumber = new FormControl('', [ Validators.required, Validators.minLength(13)]);
        this.renewalForm = new FormGroup({
            cardNumber: this.cardNumber,
            referenceNumber: this.referenceNumber
        })
        try {
          if (paypal) {
            paypal.Button.render({
              env: 'production',
              commit: true,
              style: {
                label: 'paypal',
                // size:  'responsive',
                // shape: 'rect',
                color: 'gold',
                tagline: false
              },
              payment: this.paypalPaymentClick.bind(this),
              onAuthorize: this.paypalPaymentApproved.bind(this)
            }, '#paypal-button');
          }
        } catch (error) {}
    }
    paypalPaymentClick() {
    }

    paypalPaymentApproved() {
    }
    
    a(x) {
      return typeof x
    }

    hasten(n) {
      if (this.tab == 3) {
        return false;
      }
      this.tab = n;
    }

    nextButtonStatus() {
      return null;
    }

    nextPrev(n) {
      this.tab += n;
    }
    
    ngOnDestroy() {
        // remove the the body classes
        // this.body.classList.remove('skin-blue');
        // this.body.classList.remove('layout-top-nav');
    }
}
