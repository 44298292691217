import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingAndErrors } from '../../../../classes';
import { UserDataApi, LoopBackAuth, SDKToken } from '../../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';

interface ResetPasswordForm {
  usercode: string,
  retypeUsercode: string
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends LoadingAndErrors implements OnInit {
  REGEX = {
    USERCODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)])[A-Za-z\d$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)]{8,}$/
  };
  state;

  data: ResetPasswordForm = {
    usercode: undefined,
    retypeUsercode: undefined
  };
  success: boolean;

  bodyClasses = 'skin-blue layout-top-nav';
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userDataApi: UserDataApi,
    private loopBackAuth: LoopBackAuth
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParamMap
      .subscribe(queryParams => {
        this.state = {...queryParams};
      });
    
    if (!this.state.params.access_token) {
      this.router.navigate(['/admin/login']);
    } 

    // add the the body classes
    this.body.classList.add('skin-blue');
    this.body.classList.add('layout-top-nav');
    this.body.classList.remove('sidebar-collapse');
    this.body.classList.remove('sidebar-mini'); 
  }

  submit(form) {
    if (form.valid) {
      this.loading.form = true;
      this.loopBackAuth.setToken(new SDKToken({ id: this.state.params.access_token }));
      this.userDataApi
        .setPassword(this.data.usercode)
        .pipe(finalize(() => {
          this.loading.form = false;
          this.loopBackAuth.clear();
        }))
        .subscribe(
          () => {
            this.success = true;
          },
          error => {
            this.errors.forgot = error.message;
          }
        );
    }
  }
}
