import { Component } from '@angular/core';
import {
    FormGroup,
    FormBuilder
} from '@angular/forms';
import { LoopBackConfig, EmailTemplateApi, EmailLogApi } from '../../../../assets/js/lb-sdk';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import { USER_ROLES } from '../../../enums';
import { BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BasicList } from '../../../classes';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.css']
})

export class EmailLogsComponent extends BasicList {
  USER_ROLES: typeof USER_ROLES = USER_ROLES;
  host:string = LoopBackConfig.getPath();

  filterForm: FormGroup;
  
  params = {
    where: {},
    order : 'dateSent DESC'
  };

  constructor(
    private loopBackAuth: LoopBackAuth,
    private emailTemplate: EmailTemplateApi,
    private emailLog: EmailLogApi,
    private ModalService: BsModalService,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.settings = {
      api: emailLog
    };
    this.filterForm = this.formBuilder.group({
      referenceNumber: '',
      emailTo: ''
    })
   }
  
  filter() {
    this.f.referenceNumber.value ? this.params.where['referenceNumber'] = this.f.referenceNumber.value : delete this.params.where['referenceNumber'];
    this.f.emailTo.value ? this.params.where['emailTo'] = this.f.emailTo.value : delete this.params.where['emailTo'];
    this.getList();
  }

  get f() { return this.filterForm.controls; }


  //  getList() {
  //      console.log(this.pagination);
  //      console.log(this.getrefNumber.value);
  //      console.log(this.getemail.value);
  //      // this.loading.list = true;
  //      this.loading.product = true;
  //      // console.log(this.name.value);
  //      // console.log(this.archived.value);
  //      let where = { };
  //      if(this.getrefNumber.value){
  //          where['referenceNumber'] =  (this.getrefNumber.value) ;
  //      }
  //      if(this.getemail.value){
  //        where['emailTo'] = (this.getemail.value)
  //      }
  //      // console.log(where);

  //      this.EmailLog.find({ where: where  , order : 'dateSent DESC' })
  //      .subscribe(
  //          response => {
  //              // this.loading.list = false;
  //              this.loading.product = false;
  //              this.list = this.paginate(response);
  //              this.pagination.total = response.length;
  //          },
  //          error => {
  //              // this.loading.list = false;
  //              this.loading.product = false;
  //              this.errors.products = error.message;
  //              // this.list = [];
  //          }
  //      );
  //  }

  //  isLoading():boolean {
  //      return Object.keys(this.loading).reduce((loading, key):boolean => {
  //          return loading || this.loading[key];
  //          // console.log(data);
  //      }, false);
  //  }

  //  paginate(data) {
  //        const result = data.filter((tmpl,key) => {
  //          if(this.pagination.page == 1,2,3,4,5,6,7,8,9,10){
  //              return key < this.pagination.limit;
  //          }
  //          else {
  //              return key < this.pagination.limit * this.pagination.page && key >= this.pagination.limit * (this.pagination.page - 1,2,3,4,5,6,7,8,9,10);
  //          }
  //      });

  //      return result;
  //  }

}
