import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
    <h4>
    {{title}}
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalWithComponent()">
    <span aria-hidden="true">&times;</span>
    </button>
    </h4>
    </div>
    <div class="modal-body">
    <div>
    <img [src]="image" style="width: 50%; margin-left: 24%;" />
    </div>
    <div class="card-description" [innerHTML]="description"></div>
    </div>
    <div class="modal-footer">
    <button class="btn btn-default" (click)="closeModalWithComponent()">Close</button>
    </div>
    `
})
export class ModalContentComponent {
    public title: string;
    public image: string;
    public description: string;
    public list: any[] = [];

    constructor(public bsModalRef: BsModalRef) {}

    closeModalWithComponent() {
        this.bsModalRef.hide();
    }
}
