import { Component, OnInit } from '@angular/core';
import { BasicList } from '../../../classes';
import { OtpApi } from '../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment'

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent extends BasicList {
  params = {};

  constructor(
    private toastr: ToastrService,
    private optApi: OtpApi
  ) {
    super();
    this.settings = {
      api: optApi
    };
  }

  generate() {
    this.loading.otp = true;
    this.optApi
      .generate()
      .pipe(finalize(() => {
        this.loading.otp = false;
      }))
      .subscribe(
        data => {
          this.toastr.success(data.response.code + ' is successfully added.', 'SUCCESS');
          this.getList();
        },
        error => {
          this.toastr.error(error.message, 'ERROR');
        }
      );
  }
}
