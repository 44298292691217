export enum COUNTRIES {
  "Afghanistan" = "AF",
  "Åland Islands" = "AX",
  "Albania" = "AL",
  "Algeria" = "DZ",
  "American Samoa" = "AS",
  "Andorra" = "AD",
  "Angola" = "AO",
  "Anguilla" = "AI",
  "Antigua and Barbuda" = "AG",
  "Argentina" = "AR",
  "Armenia" = "AM",
  "Aruba" = "AW",
  "Australia" = "AU",
  "Austria" = "AT",
  "Azerbaijan" = "AZ",
  "Bahamas" = "BS",
  "Bahrain" = "BH",
  "Bangladesh" = "BD",
  "Barbados" = "BB",
  "Belarus" = "BY",
  "Belgium" = "BE",
  "Belize" = "BZ",
  "Benin" = "BJ",
  "Bermuda" = "BM",
  "Bhutan" = "BT",
  "Bolivia" = "BO",
  "Bonaire, Sint Eustatius and Saba" = "BQ",
  "Bosnia and Herzegovina" = "BA",
  "Botswana" = "BW",
  "Bouvet Islands" = "BV",
  "Brazil" = "BR",
  "British Antarctic Territory" = "AQ",
  "British Indian Ocean Territory" = "IO",
  "British Virgin Islands" = "VG",
  "Brunei" = "BN",
  "Bulgaria" = "BG",
  "Burkina Faso" = "BF",
  "Burundi" = "BI",
  "Cambodia" = "KH",
  "Cameroon" = "CM",
  "Canada" = "CA",
  "Cape Verde" = "CV",
  "Cayman Islands" = "KY",
  "Central African Republic" = "CF",
  "Chad" = "TD",
  "Chile" = "CL",
  "China" = "CN",
  "Christmas Island" = "CX",
  "Cocos (Keeling) Island" = "CC",
  "Colombia" = "CO",
  "Comoros" = "KM",
  "Congo (Brazzaville)" = "CG",
  "Congo, Democratic Republic" = "CD",
  "Cook Islands" = "CK",
  "Costa Rica" = "CR",
  "Côte d'Ivoire (Ivory Coast)" = "CI",
  "Croatia" = "HR",
  "Cuba" = "CU",
  "Curaçao" = "CW",
  "Cyprus" = "CY",
  "Czech Republic" = "CZ",
  "Denmark" = "DK",
  "Djibouti" = "DJ",
  "Dominica" = "DM",
  "Dominican Republic" = "DO",
  "East Timor" = "TL",
  "Ecuador" = "EC",
  "Egypt" = "EG",
  "El Salvador" = "SV",
  "Equatorial Guinea" = "GQ",
  "Eritrea" = "ER",
  "Estonia" = "EE",
  "Ethiopia" = "ET",
  "Falkland Islands" = "FK",
  "Faroe Islands" = "FO",
  "Fiji" = "FJ",
  "Finland" = "FI",
  "France" = "FR",
  "French Guiana" = "GF",
  "French Polynesia" = "PF",
  "French Southern and Antarctic Territories" = "TF",
  "Gabon" = "GA",
  "Gambia" = "GM",
  "Georgia" = "GE",
  "Germany" = "DE",
  "Ghana" = "GH",
  "Gibraltar" = "GI",
  "Greece" = "GR",
  "Greenland" = "GL",
  "Grenada" = "GD",
  "Guadeloupe" = "GP",
  "Guam" = "GU",
  "Guatemala" = "GT",
  "Guernsey" = "GG",
  "Guinea" = "GN",
  "Guinea Bissau" = "GW",
  "Guyana" = "GY",
  "Haiti" = "HT",
  "Heard and McDonald Islands" = "HM",
  "Honduras" = "HN",
  "Hong Kong" = "HK",
  "Hungary" = "HU",
  "Iceland" = "IS",
  "India" = "IN",
  "Indonesia" = "ID",
  "Iran" = "IR",
  "Iraq" = "IQ",
  "Ireland" = "IE",
  "Isle of Man" = "IM",
  "Israel" = "IL",
  "Italy" = "IT",
  "Jamaica" = "JM",
  "Japan" = "JP",
  "Jersey" = "JE",
  "Jordan" = "JO",
  "Kazakhstan" = "KZ",
  "Kenya" = "KE",
  "Kiribati" = "KI",
  "Korea, North" = "KP",
  "Korea, South" = "KR",
  "Kuwait" = "KW",
  "Kyrgyzstan" = "KG",
  "Laos" = "LA",
  "Latvia" = "LV",
  "Lebanon" = "LB",
  "Lesotho" = "LS",
  "Liberia" = "LR",
  "Libya" = "LY",
  "Liechtenstein" = "LI",
  "Lithuania" = "LT",
  "Luxembourg" = "LU",
  "Macau" = "MO",
  "Macedonia" = "MK",
  "Madagascar" = "MG",
  "Malawi" = "MW",
  "Malaysia" = "MY",
  "Maldives" = "MV",
  "Mali" = "ML",
  "Malta" = "MT",
  "Marshall Islands" = "MH",
  "Martinique" = "MQ",
  "Mauritania" = "MR",
  "Mauritius" = "MU",
  "Mayotte" = "YT",
  "Mexico" = "MX",
  "Micronesia" = "FM",
  "Moldova" = "MD",
  "Monaco" = "MC",
  "Mongolia" = "MN",
  "Montenegro" = "ME",
  "Montserrat" = "MS",
  "Morocco" = "MA",
  "Mozambique" = "MZ",
  "Myanmar" = "MM",
  "Namibia" = "NA",
  "Nauru" = "NR",
  "Nepal" = "NP",
  "Netherlands" = "NL",
  "New Caledonia" = "NC",
  "New Zealand" = "NZ",
  "Nicaragua" = "NI",
  "Niger" = "NE",
  "Nigeria" = "NG",
  "Niue" = "NU",
  "Norfolk Island" = "NF",
  "Northern Mariana Islands" = "MP",
  "Norway" = "NO",
  "Oman" = "OM",
  "Pakistan" = "PK",
  "Palau" = "PW",
  "Palestine" = "PS",
  "Panama" = "PA",
  "Papua New Guinea" = "PG",
  "Paraguay" = "PY",
  "Peru" = "PE",
  "Philippines" = "PH",
  "Pitcairn Islands" = "PN",
  "Poland" = "PL",
  "Portugal" = "PT",
  "Puerto Rico" = "PR",
  "Qatar" = "QA",
  "Réunion" = "RE",
  "Romania" = "RO",
  "Russia" = "RU",
  "Rwanda" = "RW",
  "Saint Barthélemy" = "BL",
  "Saint Helena, Ascension and Tristan da Cunha" = "SH",
  "Saint Kitts and Nevis" = "KN",
  "Saint Lucia" = "LC",
  "Saint Martin" = "MF",
  "Saint Pierre and Miquelon" = "PM",
  "Saint Vincent and the Grenadines" = "VC",
  "Samoa" = "WS",
  "San Marino" = "SM",
  "Sao Tome and Principe" = "ST",
  "Saudi Arabia" = "SA",
  "Senegal" = "SN",
  "Serbia" = "RS",
  "Seychelles" = "SC",
  "Sierra Leone" = "SL",
  "Singapore" = "SG",
  "Sint Maarten" = "SX",
  "Slovakia" = "SK",
  "Slovenia" = "SI",
  "Solomon Islands" = "SB",
  "Somalia" = "SO",
  "South Africa" = "ZA",
  "South Georgia and the South Sandwich Islands" = "GS",
  "South Sudan" = "SS",
  "Spain" = "ES",
  "Sri Lanka" = "LK",
  "Sudan" = "SD",
  "Suriname" = "SR",
  "Svalbard and Jan Mayen" = "SJ",
  "Swaziland" = "SZ",
  "Sweden" = "SE",
  "Switzerland" = "CH",
  "Syria" = "SY",
  "Taiwan" = "TW",
  "Tajikistan" = "TJ",
  "Tanzania" = "TZ",
  "Thailand" = "TH",
  "Togo" = "TG",
  "Tokelau" = "TK",
  "Tonga" = "TO",
  "Trinidad and Tobago" = "TT",
  "Tunisia" = "TN",
  "Turkey" = "TR",
  "Turkmenistan" = "TM",
  "Turks and Caicos Islands" = "TC",
  "Tuvalu" = "TV",
  "U.S. Virgin Islands" = "VI",
  "Uganda" = "UG",
  "Ukraine" = "UA",
  "United Arab Emirates" = "AE",
  "United Kingdom" = "GB",
  "United States" = "US",
  "United States Minor Outlying Islands" = "UM",
  "Uruguay" = "UY",
  "Uzbekistan" = "UZ",
  "Vanuatu" = "VU",
  "Vatican" = "VA",
  "Venezuela" = "VE",
  "Vietnam" = "VN",
  "West Sahara" = "EH",
  "Wallis and Futuna" = "WF",
  "Yemen" = "YE",
  "Zambia" = "ZM",
  "Zimbabwe" = "ZW"
};

export const COUNTRIES_THAT_DO_NOT_REQUIRE_POSTALCODES = [
  "AX",
  "AW",
  "AU",
  "CL",
  "CC",
  "CO",
  "CD",
  "TL",
  "TF",
  "GG",
  "HK",
  "IE",
  "IM",
  "JE",
  "MO",
  "MX",
  "ME",
  "NZ",
  "PS",
  "PE",
  "PN",
  "GS",
  "BL",
  "MF",
  "WS",
  "RS",
  "SJ",
  "TH",
  "TK",
  "TR",
  "UY",
  "VE",
  "EH"
];
