/* tslint:disable */
import {
  UserData
} from '../index';

declare var Object: any;
export interface CustomerCardInterface {
  "UserDataId": number;
  "cardType": string;
  "planId"?: string;
  "cardNumber"?: string;
  "policyNumber"?: string;
  "referenceNumber"?: string;
  "dateCreated"?: Date;
  "dateValidated"?: Date;
  "expirationDate"?: Date;
  "id"?: number;
  user?: UserData;
}

export class CustomerCard implements CustomerCardInterface {
  "UserDataId": number;
  "cardType": string;
  "planId": string;
  "cardNumber": string;
  "policyNumber": string;
  "referenceNumber": string;
  "dateCreated": Date;
  "dateValidated": Date;
  "expirationDate": Date;
  "id": number;
  user: UserData;
  constructor(data?: CustomerCardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomerCard`.
   */
  public static getModelName() {
    return "CustomerCard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CustomerCard for dynamic purposes.
  **/
  public static factory(data: CustomerCardInterface): CustomerCard{
    return new CustomerCard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomerCard',
      plural: 'CustomerCards',
      path: 'CustomerCards',
      idName: 'id',
      properties: {
        "UserDataId": {
          name: 'UserDataId',
          type: 'number'
        },
        "cardType": {
          name: 'cardType',
          type: 'string'
        },
        "planId": {
          name: 'planId',
          type: 'string'
        },
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "policyNumber": {
          name: 'policyNumber',
          type: 'string'
        },
        "referenceNumber": {
          name: 'referenceNumber',
          type: 'string'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date',
          default: new Date(0)
        },
        "dateValidated": {
          name: 'dateValidated',
          type: 'Date',
          default: new Date(0)
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserData',
          model: 'UserData',
          relationType: 'belongsTo',
                  keyFrom: 'UserDataId',
          keyTo: 'id'
        },
      }
    }
  }
}
