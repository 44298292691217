import { NgModule, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { UserDataApi, LoopBackAuth } from '../../../../../assets/js/lb-sdk';

interface ChangePasswordForm {
  id?: string,
  usercode?: string,
  retypeUsercode?: string,
  currentUsercode?: string,
  password?: string
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  REGEX = {
    USERCODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)])[A-Za-z\d$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)]{8,}$/
  };
  data: ChangePasswordForm;
  error: string;
  loading: boolean = false;

  constructor(
    public modal: BsModalRef,
    private userDataApi: UserDataApi,
    private loopBackAuth: LoopBackAuth
  ) { }

  ngOnInit() {
    this.data = this.data || {};
    console.log('this data : ', this.data);
  }

  dismiss(reason: string) {
    this.modal.content.reason = reason;
    this.modal.hide();
  }

  close(form) {
    this.error = undefined;
    if (form.valid) {
      this.loading = true;
      if (this.data.id) {
        this.userDataApi
          .changePasswordById(this.data.id, {
            newPassword: this.data.usercode
          })
          .pipe(finalize(() => {
            this.loading = false;
          }))
          .subscribe(
            () => {
              this.modal.content.result = 'done';
              this.modal.hide();
            },
            error => {
              this.error = error && error.message || 'Failed to update password';
            }
          );
      } else {
        this.userDataApi
          .changePassword(this.data.currentUsercode, this.data.usercode)
          .pipe(finalize(() => {
            this.loading = false;
          }))
          .subscribe(
            () => {
              this.userDataApi
                .findById(this.getUser().id, { include: { roles: 'role' } })
                .subscribe(user => {
                  this.loopBackAuth.setUser(user);
                });
              this.modal.content.result = 'done';
              this.modal.hide();
            },
            error => {
              this.error = error && error.message || 'Failed to update password';
            }
          );
      }
    }
  }

  getUser() {
    if (this.data.id) {
      return { id: this.data.id };
    } else {
      return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }
  }

  checkForm(form) {
    console.log(form.valid);
    let ret = false;
    if(!form.valid){
      ret = true;
    }

    if(!this.data.usercode) {
      ret = true;
    }

    if(!this.data.retypeUsercode) {
      ret = true;
    }

    if(this.data.usercode !== this.data.retypeUsercode) {
      ret = true;
    }

    return ret;
  }
}
