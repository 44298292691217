import { Component } from '@angular/core';
import { BasicList } from '../../../classes';
import { ExternalApi, UserDataApi, LoopBackConfig, UserData, CustomerCardApi, OrApi } from '../../../../assets/js/lb-sdk';
import { BsModalService } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { USER_ROLES } from '../../../enums';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-or',
  templateUrl: './or.component.html',
  styleUrls: ['./or.component.css']
})
export class OrComponent extends BasicList {
  params = {
    where: {},
    order : 'dateCreated DESC'
  };
  host:string = LoopBackConfig.getPath();
  filterForm: FormGroup;
  sending = {};

  constructor(
    private userDataApi: UserDataApi,
    private externalApi: ExternalApi,
    private ModalService: BsModalService,
    private loopBackAuth: LoopBackAuth,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private customerCardApi:CustomerCardApi,
    private orApi: OrApi
  ) {
    super();
    // this.body.classList.add('sidebar-mini');
    this.filterForm = this.formBuilder.group({
      svpOrderId: '',
      osOrderId: '',
      mvpOrderId: ''
    })
    this.settings = {
      api: orApi,
      ModalService: ModalService
    };
  }

  onChangeFilter() {
    this.pagination = {
      page: 1,
      limit: 10,
      total: 0
    }
  }

  filter() {
    this.f.svpOrderId.value ? this.params.where['svpOrderId'] = this.f.svpOrderId.value : delete this.params.where['svpOrderId'];
    this.f.osOrderId.value ? this.params.where['osOrderId'] = this.f.osOrderId.value : delete this.params.where['osOrderId'];
    this.f.mvpOrderId.value ? this.params.where['mvpOrderId'] = this.f.mvpOrderId.value : delete this.params.where['mvpOrderId'];
    this.getList();
  }

  get f() { return this.filterForm.controls; }

  removeZeroPadding() {
    if (this.f.osOrderId.value) {
        this.filterForm.patchValue({
            osOrderId: [parseInt(this.f.osOrderId.value)]
        });
    }
  }

  resendOR(or) {
    const conf = confirm("Are you sure you want to resend this OR?");
    if (conf) {
      console.log(or);
      this.sending[or.id] = true;
      this.externalApi
        .sendOR({ 
            SVOrderId: or.svpOrderId ? or.svpOrderId : null,
            OSOrderId: or.osOrderId ? or.osOrderId : null,
            MVOrderId: or.mvpOrderId ? or.mvpOrderId : null
        })
        .pipe(finalize(() => {
            this.sending[or.id] = false;
        }))
        .subscribe(
          data => {
            console.log('data', data);
            or.status = true;
            this.toast.success('Success!', 'OR successfully sent!');
          },
          error => {
            console.log('error', error);
            or.status = false;
            this.toast.error('ERROR!', 'Sending OR failed.');
          }
        );
    }
  }
}
