/* tslint:disable */
import {
  OrderItem
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "id"?: string;
  "reference": string;
  "paymentMethod": number;
  "dateCreated": Date;
  "lastUpdated": Date;
  "totalDiscount"?: number;
  "totalAmount": number;
  "ORnumber"?: string;
  "ORDate"?: Date;
  "payeeStreet"?: string;
  "payeePhone"?: string;
  "payeeEmail"?: string;
  "payeeFirstName": string;
  "payeeLastName"?: string;
  "payeeMiddleName"?: string;
  "payeeExtension"?: string;
  "payeeCity"?: string;
  "payeeProvince"?: string;
  "payeePostalCode"?: string;
  "payeeCountry"?: string;
  "payeeCountryCode"?: string;
  "ticketNumber"?: string;
  "totalVat"?: number;
  "totalNetOfVat"?: number;
  orderItems?: OrderItem[];
}

export class Order implements OrderInterface {
  "id": string;
  "reference": string;
  "paymentMethod": number;
  "dateCreated": Date;
  "lastUpdated": Date;
  "totalDiscount": number;
  "totalAmount": number;
  "ORnumber": string;
  "ORDate": Date;
  "payeeStreet": string;
  "payeePhone": string;
  "payeeEmail": string;
  "payeeFirstName": string;
  "payeeLastName": string;
  "payeeMiddleName": string;
  "payeeExtension": string;
  "payeeCity": string;
  "payeeProvince": string;
  "payeePostalCode": string;
  "payeeCountry": string;
  "payeeCountryCode": string;
  "ticketNumber": string;
  "totalVat": number;
  "totalNetOfVat": number;
  orderItems: OrderItem[];
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "paymentMethod": {
          name: 'paymentMethod',
          type: 'number',
          default: 0
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "lastUpdated": {
          name: 'lastUpdated',
          type: 'Date'
        },
        "totalDiscount": {
          name: 'totalDiscount',
          type: 'number',
          default: 0
        },
        "totalAmount": {
          name: 'totalAmount',
          type: 'number',
          default: 0
        },
        "ORnumber": {
          name: 'ORnumber',
          type: 'string'
        },
        "ORDate": {
          name: 'ORDate',
          type: 'Date'
        },
        "payeeStreet": {
          name: 'payeeStreet',
          type: 'string'
        },
        "payeePhone": {
          name: 'payeePhone',
          type: 'string'
        },
        "payeeEmail": {
          name: 'payeeEmail',
          type: 'string'
        },
        "payeeFirstName": {
          name: 'payeeFirstName',
          type: 'string'
        },
        "payeeLastName": {
          name: 'payeeLastName',
          type: 'string'
        },
        "payeeMiddleName": {
          name: 'payeeMiddleName',
          type: 'string'
        },
        "payeeExtension": {
          name: 'payeeExtension',
          type: 'string'
        },
        "payeeCity": {
          name: 'payeeCity',
          type: 'string'
        },
        "payeeProvince": {
          name: 'payeeProvince',
          type: 'string'
        },
        "payeePostalCode": {
          name: 'payeePostalCode',
          type: 'string'
        },
        "payeeCountry": {
          name: 'payeeCountry',
          type: 'string'
        },
        "payeeCountryCode": {
          name: 'payeeCountryCode',
          type: 'string'
        },
        "ticketNumber": {
          name: 'ticketNumber',
          type: 'string'
        },
        "totalVat": {
          name: 'totalVat',
          type: 'number',
          default: 0
        },
        "totalNetOfVat": {
          name: 'totalNetOfVat',
          type: 'number',
          default: 0
        },
      },
      relations: {
        orderItems: {
          name: 'orderItems',
          type: 'OrderItem[]',
          model: 'OrderItem',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
      }
    }
  }
}
