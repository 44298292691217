import { NgModule, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoopBackAuth, UserDataApi } from '../../../../assets/js/lb-sdk/services';

// import { UserIdleService } from 'angular-user-idle';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
    constructor(
        public router: Router,
        private loopBackAuth: LoopBackAuth,
        private userDataApi: UserDataApi,
        // private userIdle: UserIdleService
    ) { }

    ngOnInit() {
        // Start watching for user inactivity.
        // this.userIdle.startWatching();

        // Start watching when user idle is starting.

        // this.userIdle.onTimerStart().subscribe(count => {
        //   console.log(count);
        //   // show idle timeout

        // });

        // Start watch when time is up.
        // this.userIdle.onTimeout().subscribe(() => {
        //   this.check();
        //   // console.log('Time is up!');
        //   // log out user
        // });
    }

    getUser() {
        return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }

    signOut() {
        this.userDataApi
        .logout()
        .subscribe(
            () => {
                this.loopBackAuth.clear();
                this.router.navigateByUrl('/');
            }
        );
    }
}
