import { Component } from '@angular/core';
import { BasicList } from '../../../classes';
import { SMSConfigurationApi, ProductApi, LoopBackConfig, SMSConfiguration } from '../../../../assets/js/lb-sdk';
import { BsModalService } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { SMSFormComponent } from './sms-form/sms-form.component';
import { ToastrService } from 'ngx-toastr';
import { USER_ROLES } from '../../../enums';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sms-list',
  templateUrl: './sms-list.component.html',
  styleUrls: ['./sms-list.component.css']
})
export class SMSConfigurationComponent extends BasicList {
  params = {};
  USER_ROLES: typeof USER_ROLES = USER_ROLES;
  host:string = LoopBackConfig.getPath();
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  products = {}

  constructor(
    private smsConfigurationApi: SMSConfigurationApi,
    private ModalService: BsModalService,
    private loopBackAuth: LoopBackAuth,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private productApi: ProductApi
  ) {
    super();
    // this.body.classList.add('sidebar-mini');
    this.settings = {
      createComponent: SMSFormComponent,
      api: smsConfigurationApi,
      ModalService: ModalService
    };
    this.loading.product = true;
    productApi
      .find()
      .pipe(finalize(() => {
        this.loading.product = false;
      }))
      .subscribe(
        (response: any) => {
          this.products = response;
        },
        error => {
          this.errors.products = error.message;
        }
      );
  }

  edit(sms: SMSConfiguration) {
    const smsModal = this.ModalService
      .show(SMSFormComponent, {
        initialState: {
          data: sms
        }
      });
    const subscription = this.ModalService.onHidden
      .subscribe(() => {
        if (smsModal.content) {
          if (smsModal.content.result) {
            this.toast.success('Successfully updated SMS!', 'Update Success');
            this.getList();
          }
        }
        subscription.unsubscribe();
      });
  }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

}
