/* tslint:disable */
import {
  CustomerInfo,
  Municipality
} from '../index';

declare var Object: any;
export interface ProvinceInterface {
  "name"?: string;
  "key"?: number;
  "archived"?: boolean;
  "id"?: number;
  provinceId?: CustomerInfo[];
  roles?: Municipality[];
}

export class Province implements ProvinceInterface {
  "name": string;
  "key": number;
  "archived": boolean;
  "id": number;
  provinceId: CustomerInfo[];
  roles: Municipality[];
  constructor(data?: ProvinceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Province`.
   */
  public static getModelName() {
    return "Province";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Province for dynamic purposes.
  **/
  public static factory(data: ProvinceInterface): Province{
    return new Province(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Province',
      plural: 'Provinces',
      path: 'Provinces',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "key": {
          name: 'key',
          type: 'number'
        },
        "archived": {
          name: 'archived',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        provinceId: {
          name: 'provinceId',
          type: 'CustomerInfo[]',
          model: 'CustomerInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'id'
        },
        roles: {
          name: 'roles',
          type: 'Municipality[]',
          model: 'Municipality',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'provinceId'
        },
      }
    }
  }
}
