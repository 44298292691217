import { Component, OnInit, TemplateRef } from '@angular/core';
import { BasicList } from '../../../classes';
import { ExternalApi, CustomerCardApi } from '../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

const MEMBERSHIP_VOUCHER = 'eCard';

@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.css']
})
export class ResendEmailComponent implements OnInit {
	orderId:any = "";
  referenceLoading: boolean = false;
	ecardLoading: boolean = false;
  customer:any = [];
  members:any = [];

  firstName:any = "";
  lastName:any = "";

  emailrefId:any;
  emailrefId2:any;
  deleteModal: BsModalRef;
  emailTemplateData: any;
  emailTemplateData2: any;
  ref2: any;

  constructor(
  	private toast: ToastrService,
    private externalApi: ExternalApi,
    private formBuilder: FormBuilder,
    private ModalService: BsModalService,
    private customerCardApi: CustomerCardApi
  ) {

  }

  ngOnInit() {

  }

  searchReference() {
    this.customer=[];
  	console.log('fetching...', this.orderId);
  	this.referenceLoading = true;
    this.externalApi.searchOrderID({ orderID: this.orderId })
    .subscribe(
      response => {
        this.referenceLoading = false;
        // console.log(response.response);

        if(response.response){

          for(var i in response.response.data) {
            let customer = response.response.data[i];
            customer.reference_number = customer.reference_number ? customer.reference_number.split(',') : null; 
            customer.qty = parseInt(customer.qty);

            if(customer.owner['Is this a gift?']) {
              customer.gift = {
                status: true,
                email: customer.owner['Recipients Email Address']
              }
            }

            this.customer.push(customer);
            // this.customer = response.response.data[i];
            // this.customer.reference_number = this.customer.reference_number.split(',');
            // this.customer.qty = parseInt(this.customer.qty);
          }

          console.log('customer', this.customer);
        }
        else {
          this.toast.error('ERROR!', 'Order not found');
        }


      },
      error => {
        // this.toastr.error(error.code, 'ERROR');
        this.referenceLoading = false;
      }
    );
  }


  resendRef(ref) {
    const conf = confirm("Are you sure you want to resend reference number "+ref+"?");
    if(conf) {
      console.log(ref);
      this.referenceLoading = true;
      this.customerCardApi
          .resendReferenceNumber({ referenceNumber : ref })
        .pipe(finalize(() => {
          this.referenceLoading = false;
        }))
        .subscribe(
          data => {
            console.log('data', data);
            this.toast.success('Success!', 'Reference Number was successfully sent' );
          },
          error => {
            console.log('error', error);
            this.toast.error('ERROR!', 'Sending Reference Number failed.');
          }
        );
    }
  }


  resendanothermail(){
    console.log(this.ref2);
    console.log(this.emailrefId);
    const conf = confirm ("Are you sure you want to resend reference number "+this.ref2+" to "+this.emailrefId+"?");
    if(conf){
      this.referenceLoading = true;
      this.customerCardApi.resendReferenceNumber({referenceNumber : this.ref2, email2: this.emailrefId})
        .pipe(finalize(() => {
          this.referenceLoading = false;
        }))
        .subscribe(
          data => {
            console.log('data', data);
            this.deleteModal.hide();
            this.toast.success('Success!', 'Reference Number was successfully sent' );
          },
          error => {
            console.log('error', error);
            this.toast.error('ERROR!', 'Sending Reference Number failed.');
          }
        );
    }
  }



  searchVoucher() {
    console.log('fetching...', this.firstName, this.lastName);
    this.ecardLoading = true;
    this.externalApi.searchName({ firstName: this.firstName, lastName: this.lastName })
    .subscribe(
      response => {
        console.log(response.response.results);
        this.members = response.response.results;

        this.ecardLoading = false;
      },
      error => {
        // this.toastr.error(error.code, 'ERROR');
        this.ecardLoading = false;
      }
    );
  }

  resendVoucher(ref) {
    const conf = confirm("Are you sure you want to resend the eCard of this reference number?");
    if(conf) {
      console.log(ref)
      this.ecardLoading = true;
      this.customerCardApi
        .resendVoucher({ referenceNumber : ref })
        .pipe(finalize(() => {
          this.ecardLoading = false;
        }))
        .subscribe(
          data => {
            console.log('data', data);
            this.toast.success('Success!', `${MEMBERSHIP_VOUCHER} was sent to ${data.response.Email}`);
          },
          error => {
            console.log('error', error);
            this.toast.error('ERROR!', `Sending ${MEMBERSHIP_VOUCHER} to failed.`);
          }
        );
    }
  }

  resendanothermail2(){
    console.log(this.emailrefId2);
    console.log(this.emailTemplateData2.Referenceemail3Number);
    const conf = confirm ("Are you sure you want to send eCard to "+this.emailrefId2+"?");
    if(conf){
      console.log(this.emailTemplateData2.ReferenceNumber)
      this.referenceLoading = true;
      this.customerCardApi.resendVoucher({ referenceNumber : this.emailTemplateData2.ReferenceNumber, email3: this.emailrefId2})
        .pipe(finalize(() => {
          this.referenceLoading = false;
        }))
        .subscribe(
          data => {
            console.log('data', data);
            this.deleteModal.hide();
            this.toast.success('Success!', `${MEMBERSHIP_VOUCHER} was successfully sent` );
          },
          error => {
            console.log('error', error);
            this.toast.error('ERROR!', `Sending ${MEMBERSHIP_VOUCHER} failed.`);
          }
        );
    }
  }

  removeZeroPadding() {
    if(this.orderId){
      this.orderId = parseInt(this.orderId);
    }
  }

    openDeleteModal(template: TemplateRef<any>, customer, ref) {
      // console.log(customer);
      // console.log("ref is:",ref);
      this.emailTemplateData = customer;
      this.ref2 = ref;
      // console.log("yeah",this.ref2);
      this.deleteModal = this.ModalService.show(template, { class: 'modal-md' });
  }

    openActivateModal(template: TemplateRef<any>, card) {
        console.log(card);
        this.emailTemplateData2 = card;
        this.deleteModal = this.ModalService.show(template, { class: 'modal-md' });
    }


}
