import { AppRoutingModule } from './app-routing/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule, enableProdMode } from '@angular/core';
// import { HttpModule } from '@angular/http'; // Deprecated
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SDKBrowserModule } from '../assets/js/lb-sdk';

import { RecaptchaModule } from 'ng-recaptcha';
import { MyDatePickerModule } from 'mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { CookieService } from 'ngx-cookie-service';

import { NgxSummernoteModule } from 'ngx-summernote';

import { AppComponent } from './app.component';

import { TemplatesModule } from './templates/templates.module';
import { ModalModule, BsDatepickerModule, PopoverModule  } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-toggle-switch';

// SERVICES
import { ExcelService } from './services/excel.service';
import { LoginRedirectService } from './services/login-redirect.service';
import { AuthenticationService } from './services/authentication.service';


// import { WindowService } from './services/window.service';

// DIRECTIVES
import { NumberOnlyDirective } from './directives/number.directive';
import { CurrencyOnlyDirective } from './directives/currency.directive';
import { NameOnlyDirective } from './directives/name.directive';

// COMPONENTS
// import { MainHeaderComponent } from './main/main-header/main-header.component';
// import { MainLeftSideComponent } from './main/main-left-side/main-left-side.component';
// import { MainControlSidebarComponent } from './main/main-control-sidebar/main-control-sidebar.component';
// import { MainFooterComponent } from './main/main-footer/main-footer.component';

import { AuthenticationComponent } from './pages/frontend/authentication/authentication.component';
import { LoginComponent } from './pages/frontend/authentication/login/login.component';
import { RegisterComponent } from './pages/frontend/authentication/register/register.component';
import { RegisterModalComponent } from './pages/frontend/authentication/register/register-modal.component';
import { ModalRegistrationContentComponent } from './pages/frontend/authentication/register/modal-registration-summary.component';

import { HomeComponent } from './pages/frontend/home/home.component';
import { HomeContentComponent } from './pages/frontend/home/home-content/home-content.component';
import { DashboardComponent } from './pages/frontend/dashboard/dashboard.component';

import { ProfileComponent } from './pages/frontend/profile/profile.component';
import { ProfileContentComponent } from './pages/frontend/profile/profile-content/profile-content.component';

import { ModalContentComponent } from './modals/card';
import { VerifyComponent } from './pages/frontend/authentication/verify/verify.component';

// import admin modules
import { AdminLoginComponent } from './pages/admin/authentication/login/login.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { AdminAuthenticationComponent } from './pages/admin/authentication/authentication.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { UserFormComponent } from './pages/admin/users/user-form/user-form.component'
import { ChangePasswordComponent } from './pages/admin/authentication/change-password/change-password.component'
import { ChangeUsernameComponent } from './pages/admin/authentication/change-username/change-username.component'
import { ReportsComponent } from './pages/admin/reports/reports.component';
import { DeleteModalComponent } from './templates/delete-modal/delete-modal.component'
import { MembersComponent } from './pages/admin/members/members.component';
import { ConfigurationComponent } from './pages/admin/configuration/configuration.component';
import { SMSConfigurationComponent } from './pages/admin/sms-configuration/sms-list.component';
import { SMSFormComponent } from './pages/admin/sms-configuration/sms-form/sms-form.component'
import { ForgotPasswordComponent } from './pages/admin/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/admin/authentication/reset-password/reset-password.component';
import { EmailsComponent } from './pages/admin/emails/emails.component';
import { EmailFormComponent } from './pages/admin/emails/email-form/email-form.component';
import { OtpComponent } from './pages/admin/otp/otp.component';
import { BranchComponent } from './pages/admin/branch/branch.component';
import { BranchFormComponent } from './pages/admin/branch/branch-form/branch-form.component';

// static
import { AccessDeniedComponent } from './pages/static/access-denied/access-denied.component';
import { NoDataFoundComponent } from './pages/static/no-data-found/no-data-found.component';
import { NotFoundComponent } from './pages/static/not-found/not-found.component';
import { MaintenanceComponent } from './pages/static/maintenance/maintenance.component';
import { PageExpiredComponent } from './pages/static/page-expired/page-expired.component';

//pipes
import { ApplicationPipesModule } from './pipes/pipes.module';
import { ResendEmailComponent } from './pages/admin/resend-email/resend-email.component';
import { EmailLogsComponent } from './pages/admin/email-logs/email-logs.component';

// Renewal
import { RenewalComponent, TermsAndConditionComponent } from './pages/frontend/renewal/token/renewal.component';
import { ManualRenewalComponent } from './pages/frontend/renewal/manual/manual-renewal.component';
import { RenewalResultComponent } from './pages/frontend/renewal/result/renewal-result.component';
import { RenewalExpiredComponent } from './pages/frontend/renewal/expired/expired.component';

//Emails 
import { OrComponent } from './pages/admin/or/or.component';
import { CardReferenceLogsComponent } from './pages/admin/card-reference-logs/card-reference-logs.component';
import { AuditTrailsComponent } from './pages/admin/audit-trails/audit-trails.component';
import { AuditTrailsDetailsComponent } from './pages/admin/audit-trails/audit-trails-details/audit-trails-details.component';

@NgModule({
    declarations: [
        AppComponent,
        AuthenticationComponent,
        LoginComponent,
        RegisterComponent,
	RegisterModalComponent,
	ModalRegistrationContentComponent,
        HomeComponent,
        NumberOnlyDirective,
        CurrencyOnlyDirective,
        NameOnlyDirective,
        DashboardComponent,
        HomeContentComponent,
        ProfileComponent,
        ProfileContentComponent,
        ModalContentComponent,
        VerifyComponent,
        AdminAuthenticationComponent,
        AdminLoginComponent,
        AdminDashboardComponent,
        AccessDeniedComponent,
        NoDataFoundComponent,
        NotFoundComponent,
        UsersComponent,
        UserFormComponent,
        ChangePasswordComponent,
        ReportsComponent,
        MaintenanceComponent,
        ChangeUsernameComponent,
        MembersComponent,
        ConfigurationComponent,
        SMSConfigurationComponent,
        SMSFormComponent,
        EmailsComponent,
        EmailFormComponent,
        OtpComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        BranchComponent,
        BranchFormComponent,
        ResendEmailComponent,
        EmailLogsComponent,
        RenewalComponent,
        ManualRenewalComponent,
        RenewalResultComponent,
        PageExpiredComponent,
        RenewalExpiredComponent,
        TermsAndConditionComponent,
        OrComponent,
        CardReferenceLogsComponent,
        AuditTrailsComponent,
        AuditTrailsDetailsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        // HttpModule, // Deprecated
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule.forRoot(),
        NgSelectModule,
        MyDatePickerModule,
        // HttpClientModule,
        TemplatesModule,
        // RepositoryModule,
        SDKBrowserModule.forRoot(),
        ModalModule.forRoot(),
        ToastrModule.forRoot({
            disableTimeOut: true,
            preventDuplicates: true,
            closeButton: true,
            maxOpened: 3
        }),
        ApplicationPipesModule,
        NgxSummernoteModule,
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        UiSwitchModule
    ],
    providers: [
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: CustomHttpInterceptor,
        //   multi: true
        // },
        CookieService,
        AuthenticationService,
        ExcelService,
        LoginRedirectService
        // WindowService
    ],
    entryComponents: [
        ModalContentComponent,
        UserFormComponent,
        ChangePasswordComponent,
        ChangeUsernameComponent,
        DeleteModalComponent,
        SMSFormComponent,
        EmailFormComponent,
        BranchFormComponent,
        OtpComponent,
        TermsAndConditionComponent,
        AuditTrailsDetailsComponent,
	RegisterModalComponent,
	ModalRegistrationContentComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
