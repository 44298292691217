import { NgModule, Component, Pipe, OnInit } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService } from 'ngx-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component'

import { UserDataApi, LoopBackAuth } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-admin-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class AdminLoginComponent implements OnInit {
    form: FormGroup;
    loginForm: FormGroup;

    username: FormControl;
    password: FormControl;
    rememberMe: FormControl;

    error?: string = null;

    constructor(
        private userDataApi: UserDataApi,
        private loopBackAuth: LoopBackAuth,
        private router: Router,
		private cookieService: CookieService,
		private ModalService: BsModalService,

    ) { }

    ngOnInit() {
        this.createFormControls();
        this.createForm();
    }

    createFormControls() {
        // this.username = new FormControl('', Validators.required);
        this.username = new FormControl('', [
            Validators.required,
            Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')
        ]);
        this.password = new FormControl('', Validators.required);
        this.rememberMe = new FormControl(false, []);
    }

    createForm() {
        this.loginForm = new FormGroup({
            username: this.username,
            password: this.password,
            rememberMe: this.rememberMe
        });
    }

    checkForm() {
        let ret = true;
        if (
            !this.username.value || !this.password.value ||
            (this.username.errors && (this.username.dirty || this.username.touched)) ||
            (this.password.errors && (this.password.dirty || this.password.touched))
        ) {
            ret = false;
        }

        return ret;
    }

    onLogin() {
        const data = {
            username : this.username.value,
            password : this.password.value,
            rememberMe: this.rememberMe.value
        };

        if (data.username && data.password) {
            this.error = undefined;

            this.userDataApi.customLogin({
                email: data.username,
                pw: data.password
            })
            .subscribe(
                response => {
                    this.loopBackAuth.setRememberMe(data.rememberMe);
                    this.loopBackAuth.setToken(response);
                    this.userDataApi
                    .findById(response.userId, { include: { roles: 'role' } })
                    .subscribe((user: any) => {
                        this.loopBackAuth.setUser(user);
                        if (user.new) {
                            this.changePassword(user);
                        } else {
                            console.log("user.roles[0].principalType : ", user.roles[0].principalType);
                            switch(user.roles[0].role.code.toString()) {
                                case '100':
                                case '101':
                                    this.router.navigateByUrl('/admin/members');
                                    break;
                                case '102':
                                    this.router.navigateByUrl('/admin/reports');
                                    break;
                                case '103':
                                    this.router.navigateByUrl('/admin/marketing');
                                    break;
                                case '104':
                                    this.router.navigateByUrl('/admin/finance');
                                case '105':
                                    this.router.navigateByUrl('/admin/reports');
                                case '900':
                                    this.userDataApi
                                    .logout()
                                    .subscribe(
                                        () => {
                                            this.loopBackAuth.clear();
                                            this.router.navigateByUrl('/');
                                        }
                                    );
                                    break;
                                default:
                                this.router.navigateByUrl('/profile');
                            }
                        }
                    })
                },
                error => {
                    switch (error.code) {
                        case 'LOGIN_FAILED':
                        this.error = 'Invalid Credentials.';
                        break;
                        case 'UNVERIFIED_EMAIL':
                        this.error = 'The email address associated to this account is not yet verified.';
                        break;
                        default:
                        this.error = error && error.message || 'Login failed. Try again.';
                        break;
                    }
                }
            );
        }
    }

    changePassword(data): void {
        const createModal = this.ModalService
            .show(ChangePasswordComponent, {
                initialState: { data },
                backdrop :'static'
            });
        const subscription = this.ModalService.onHidden
            .subscribe(() => {
                // this.loginForm.patchValue({
                //     username: '',
                //     password: '',
                //     rememberMe: ''
                // });
                this.loginForm.reset();
                this.loopBackAuth.clear();
                this.router.navigateByUrl('/admin/login??ref=1');
                subscription.unsubscribe();
            });
    }
}
