/* tslint:disable */
import {
  UserRole,
  CustomerInfo,
  CustomerCard
} from '../index';

declare var Object: any;
export interface UserDataInterface {
  "active"?: boolean;
  "firstName": string;
  "lastName"?: string;
  "username"?: string;
  "email"?: string;
  "loginAttempt"?: number;
  "expiration"?: Date;
  "dateCreated"?: Date;
  "new"?: boolean;
  "lastLogin"?: Date;
  "realm"?: string;
  "emailVerified"?: boolean;
  "id"?: number;
  "password"?: string;
  accessTokens?: any[];
  roles?: UserRole[];
  info?: CustomerInfo[];
  card?: CustomerCard[];
}

export class UserData implements UserDataInterface {
  "active": boolean;
  "firstName": string;
  "lastName": string;
  "username": string;
  "email": string;
  "loginAttempt": number;
  "expiration": Date;
  "dateCreated": Date;
  "new": boolean;
  "lastLogin": Date;
  "realm": string;
  "emailVerified": boolean;
  "id": number;
  "password": string;
  accessTokens: any[];
  roles: UserRole[];
  info: CustomerInfo[];
  card: CustomerCard[];
  constructor(data?: UserDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserData`.
   */
  public static getModelName() {
    return "UserData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserData for dynamic purposes.
  **/
  public static factory(data: UserDataInterface): UserData{
    return new UserData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserData',
      plural: 'UserData',
      path: 'UserData',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "loginAttempt": {
          name: 'loginAttempt',
          type: 'number',
          default: 0
        },
        "expiration": {
          name: 'expiration',
          type: 'Date'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "new": {
          name: 'new',
          type: 'boolean',
          default: true
        },
        "lastLogin": {
          name: 'lastLogin',
          type: 'Date'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        roles: {
          name: 'roles',
          type: 'UserRole[]',
          model: 'UserRole',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'principalId'
        },
        info: {
          name: 'info',
          type: 'CustomerInfo[]',
          model: 'CustomerInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'UserDataId'
        },
        card: {
          name: 'card',
          type: 'CustomerCard[]',
          model: 'CustomerCard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'UserDataId'
        },
      }
    }
  }
}
