/* tslint:disable */
import {
  CustomerInfo
} from '../index';

declare var Object: any;
export interface MunicipalityInterface {
  "name": string;
  "zipcode"?: number;
  "provinceId": number;
  "archived"?: boolean;
  "id"?: number;
  municipalityId?: CustomerInfo[];
}

export class Municipality implements MunicipalityInterface {
  "name": string;
  "zipcode": number;
  "provinceId": number;
  "archived": boolean;
  "id": number;
  municipalityId: CustomerInfo[];
  constructor(data?: MunicipalityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Municipality`.
   */
  public static getModelName() {
    return "Municipality";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Municipality for dynamic purposes.
  **/
  public static factory(data: MunicipalityInterface): Municipality{
    return new Municipality(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Municipality',
      plural: 'municipalities',
      path: 'municipalities',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "zipcode": {
          name: 'zipcode',
          type: 'number'
        },
        "provinceId": {
          name: 'provinceId',
          type: 'number'
        },
        "archived": {
          name: 'archived',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        municipalityId: {
          name: 'municipalityId',
          type: 'CustomerInfo[]',
          model: 'CustomerInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'id'
        },
      }
    }
  }
}
