/* tslint:disable */

declare var Object: any;
export interface RenewalInterface {
  "cardNumber": string;
  "token": string;
  "expirationDate"?: Date;
  "orderId"?: string;
  "dateRenewed"?: Date;
  "reference"?: string;
  "status": string;
  "dateCreated"?: Date;
  "id"?: number;
}

export class Renewal implements RenewalInterface {
  "cardNumber": string;
  "token": string;
  "expirationDate": Date;
  "orderId": string;
  "dateRenewed": Date;
  "reference": string;
  "status": string;
  "dateCreated": Date;
  "id": number;
  constructor(data?: RenewalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Renewal`.
   */
  public static getModelName() {
    return "Renewal";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Renewal for dynamic purposes.
  **/
  public static factory(data: RenewalInterface): Renewal{
    return new Renewal(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Renewal',
      plural: 'Renewals',
      path: 'Renewals',
      idName: 'id',
      properties: {
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "dateRenewed": {
          name: 'dateRenewed',
          type: 'Date'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'PENDING'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
