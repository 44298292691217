export class EnumConverter {
  enumeration = {};

  transform(value): string {
    return (Object.keys(this.enumeration)
      .filter(key => {
        return this.enumeration[key] === value;
      })[0] || '').split('_').join(' ');
  }
}
