import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LoopBackAuth, UserDataApi } from '../../assets/js/lb-sdk/services';

@Injectable({
  providedIn: 'root'
})
export class LoginRedirectService implements CanActivate {

	constructor(private router: Router, private loopBackAuth: LoopBackAuth) {}
    canActivate(): boolean {
        console.log('login redirect ', this.getUser());
        if (this.getUser()) {
        	console.log('login redirect getuser true');
            this.router.navigateByUrl('/admin');
            return false;
        }
        else {
        	console.log('login redirect getuser true');
            return true;
        }
    }

    getUser() {
        return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }
}
