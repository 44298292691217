import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { CustomerCardApi, CustomerInfoApi } from '../../../../../assets/js/lb-sdk/services';
import { LoopBackAuth, UserDataApi } from '../../../../../assets/js/lb-sdk/services';

import { ModalModule } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalContentComponent } from '../../../../modals/card';

import { environment } from '../../../../../environments/environment';

import * as moment from 'moment';

@Component({
    selector: 'app-profile-content',
    templateUrl: './profile-content.component.html',
    styleUrls: ['./profile-content.component.css'],
    providers: [BsModalService]
})
export class ProfileContentComponent implements OnInit {
    name:string;
    bsModalRef: BsModalRef;

    products: any = {};
    user: any;
    profile: any;
    activities: any;
    cards = {
        personal: [],
        corporate: {
            personal: [],
            dependents: []
        }
    }

    constructor(
        private customerCardApi: CustomerCardApi,
        private customerInfoApi: CustomerInfoApi,
        private loopBackAuth: LoopBackAuth,
        private httpClient: HttpClient,
        private modalService: BsModalService
    ) { }

    ngOnInit() {
        this.fetchShopProducts();
        this.getUser();

    }

    openModalWithComponent(card) {
        console.log(card);
        let list = ['Open a modal with component', 'Pass your data', 'Do something else', '...'];
        this.bsModalRef = this.modalService.show(ModalContentComponent, Object.assign({}, { class: 'gray modal-lg' }));
        this.bsModalRef.content.title = card.name;
        this.bsModalRef.content.image = card.image;
        this.bsModalRef.content.description = card.description;
        this.bsModalRef.content.list = list;
        setTimeout(() => {
          list.push('PROFIT!!!');
        }, 2000);
    }

    closeModalWithComponent() {
        this.bsModalRef.hide();
    }

    fetchShopProducts() {
        this.httpClient.get('https://shop.maxicare.com.ph/api/products/fetchall.php')
        .subscribe(
            data => {
                const result = data['results'];
                for(var i in result) {
                    this.products[result[i].sku] = {
                        sku: result[i].sku,
                        description: result[i].description,
                        image: environment.shop + environment.magentoMediaLocation + result[i].image,
                        name: result[i].name,
                        price: result[i].price
                    }
                }

                this.fetchPersonalCards();
            },
            error => {
                console.log("Rrror", error);
            }
        );
    }

    getUser() {
        this.user = this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
        this.fetchActivities();
        this.getProfile();
    }

    getProfile() {
        this.customerInfoApi.find({ where: { UserDataId: this.user.id }, include: ['municipality', 'province'] })
        .subscribe(
            response => {
                const results = JSON.parse(JSON.stringify(response));
                console.log(results);
                this.profile = results[0];
                this.profile.birthDate = moment(this.profile.birthDate).format('LL');
            },
            error => {
                console.log('ERROR: ', error);
            }
        );
    }

    fetchActivities() {

    }

    fetchPersonalCards() {
        this.customerCardApi.find({ where: { UserDataId: this.user.id } })
        .subscribe(
            response => {
                const results = JSON.parse(JSON.stringify(response));
                for(var i in results){
                    if(this.products[results[i]['cardType']]) {
                        results[i]['image'] = this.products[results[i]['cardType']].image;
                        results[i]['name'] = this.products[results[i]['cardType']].name;
                        results[i]['description'] = this.products[results[i]['cardType']].description;
                        results[i]['price'] = this.products[results[i]['cardType']].price;
                        results[i]['expirationDate'] = moment(results[i].expirationDate).format('LL');
                    }
                }

                this.cards.personal = results;
                console.log(this.cards.personal);
            },
            error => {
                console.log('ERROR: ', error.code);
            }
        );
    }

    editProfile() {
        console.log('editing profile. please wait...');
    }


}
