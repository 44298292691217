/* tslint:disable */

declare var Object: any;
export interface GenerateCardReferenceLogInterface {
  "sku": string;
  "qty": number;
  "orderId": string;
  "itemId": string;
  "reference": string;
  "customerName": string;
  "customerEmail": string;
  "isGift"?: boolean;
  "dateCreated": Date;
  "status"?: boolean;
  "remarks"?: string;
  "dateUpdated"?: Date;
  "errorCount"?: number;
  "id"?: number;
}

export class GenerateCardReferenceLog implements GenerateCardReferenceLogInterface {
  "sku": string;
  "qty": number;
  "orderId": string;
  "itemId": string;
  "reference": string;
  "customerName": string;
  "customerEmail": string;
  "isGift": boolean;
  "dateCreated": Date;
  "status": boolean;
  "remarks": string;
  "dateUpdated": Date;
  "errorCount": number;
  "id": number;
  constructor(data?: GenerateCardReferenceLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GenerateCardReferenceLog`.
   */
  public static getModelName() {
    return "GenerateCardReferenceLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GenerateCardReferenceLog for dynamic purposes.
  **/
  public static factory(data: GenerateCardReferenceLogInterface): GenerateCardReferenceLog{
    return new GenerateCardReferenceLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GenerateCardReferenceLog',
      plural: 'GenerateCardReferenceLogs',
      path: 'GenerateCardReferenceLogs',
      idName: 'id',
      properties: {
        "sku": {
          name: 'sku',
          type: 'string'
        },
        "qty": {
          name: 'qty',
          type: 'number',
          default: 0
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "itemId": {
          name: 'itemId',
          type: 'string'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "customerName": {
          name: 'customerName',
          type: 'string'
        },
        "customerEmail": {
          name: 'customerEmail',
          type: 'string'
        },
        "isGift": {
          name: 'isGift',
          type: 'boolean',
          default: false
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'boolean',
          default: false
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "dateUpdated": {
          name: 'dateUpdated',
          type: 'Date'
        },
        "errorCount": {
          name: 'errorCount',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
