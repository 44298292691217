import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoopBackAuth } from '../../assets/js/lb-sdk/services';

@Injectable()
export class AuthenticationService implements CanActivate {
  constructor(
    private loopBackAuth: LoopBackAuth,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.loopBackAuth.getAccessTokenId()) {
      this.router.navigate(['/admin/login']); //redirect to login instead of 401
    }
    return !!this.loopBackAuth.getToken();
  }
}
