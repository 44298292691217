/* tslint:disable */
import {
  Order
} from '../index';

declare var Object: any;
export interface OrderItemInterface {
  "orderId": string;
  "productType": string;
  "planType": string;
  "quantity": number;
  "unitPrice": number;
  "unitDiscount": number;
  "discountUnit": string;
  "subTotal": number;
  "totalAmount": number;
  "totalDiscount": number;
  "SOANumber"?: string;
  "SOADate"?: Date;
  "totalVat"?: number;
  "netOfVat"?: number;
  "id"?: number;
  order?: Order;
}

export class OrderItem implements OrderItemInterface {
  "orderId": string;
  "productType": string;
  "planType": string;
  "quantity": number;
  "unitPrice": number;
  "unitDiscount": number;
  "discountUnit": string;
  "subTotal": number;
  "totalAmount": number;
  "totalDiscount": number;
  "SOANumber": string;
  "SOADate": Date;
  "totalVat": number;
  "netOfVat": number;
  "id": number;
  order: Order;
  constructor(data?: OrderItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OrderItem`.
   */
  public static getModelName() {
    return "OrderItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OrderItem for dynamic purposes.
  **/
  public static factory(data: OrderItemInterface): OrderItem{
    return new OrderItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OrderItem',
      plural: 'OrderItems',
      path: 'OrderItems',
      idName: 'id',
      properties: {
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "productType": {
          name: 'productType',
          type: 'string'
        },
        "planType": {
          name: 'planType',
          type: 'string'
        },
        "quantity": {
          name: 'quantity',
          type: 'number',
          default: 0
        },
        "unitPrice": {
          name: 'unitPrice',
          type: 'number',
          default: 0
        },
        "unitDiscount": {
          name: 'unitDiscount',
          type: 'number',
          default: 0
        },
        "discountUnit": {
          name: 'discountUnit',
          type: 'string',
          default: 'C'
        },
        "subTotal": {
          name: 'subTotal',
          type: 'number',
          default: 0
        },
        "totalAmount": {
          name: 'totalAmount',
          type: 'number',
          default: 0
        },
        "totalDiscount": {
          name: 'totalDiscount',
          type: 'number',
          default: 0
        },
        "SOANumber": {
          name: 'SOANumber',
          type: 'string'
        },
        "SOADate": {
          name: 'SOADate',
          type: 'Date'
        },
        "totalVat": {
          name: 'totalVat',
          type: 'number',
          default: 0
        },
        "netOfVat": {
          name: 'netOfVat',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
      }
    }
  }
}
