/* tslint:disable */
import {
  UserData
} from '../index';

declare var Object: any;
export interface AuditTrailInterface {
  "endpoint": string;
  "params"?: string;
  "query"?: string;
  "datetime": Date;
  "body"?: string;
  "method": string;
  "headers": string;
  "error"?: string;
  "statusCode"?: string;
  "response"?: string;
  "userId"?: number;
  "ipAddress"?: string;
  "module": string;
  "id"?: number;
  user?: UserData;
}

export class AuditTrail implements AuditTrailInterface {
  "endpoint": string;
  "params": string;
  "query": string;
  "datetime": Date;
  "body": string;
  "method": string;
  "headers": string;
  "error": string;
  "statusCode": string;
  "response": string;
  "userId": number;
  "ipAddress": string;
  "module": string;
  "id": number;
  user: UserData;
  constructor(data?: AuditTrailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AuditTrail`.
   */
  public static getModelName() {
    return "AuditTrail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AuditTrail for dynamic purposes.
  **/
  public static factory(data: AuditTrailInterface): AuditTrail{
    return new AuditTrail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AuditTrail',
      plural: 'AuditTrails',
      path: 'AuditTrails',
      idName: 'id',
      properties: {
        "endpoint": {
          name: 'endpoint',
          type: 'string'
        },
        "params": {
          name: 'params',
          type: 'string'
        },
        "query": {
          name: 'query',
          type: 'string'
        },
        "datetime": {
          name: 'datetime',
          type: 'Date'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
        "method": {
          name: 'method',
          type: 'string'
        },
        "headers": {
          name: 'headers',
          type: 'string'
        },
        "error": {
          name: 'error',
          type: 'string'
        },
        "statusCode": {
          name: 'statusCode',
          type: 'string'
        },
        "response": {
          name: 'response',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "ipAddress": {
          name: 'ipAddress',
          type: 'string'
        },
        "module": {
          name: 'module',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserData',
          model: 'UserData',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
