import { NgModule } from '@angular/core';
import { HasRolePipe } from './has-role.pipe';
import { ReplacePipe } from './replace.pipe';
import { SapDatetimePipe } from './datetime.pipe';
import { DisplayValuePipe } from './display-value.pipe';
import { MultipleDisplayValuePipe } from './multiple-display-value.pipe';
import { FromEnumPipe } from './from-enum.pipe';
import { ExpiryDatetimePipe } from './expiry.pipe';

const pipeModules = [
  HasRolePipe,
  ReplacePipe,
  SapDatetimePipe,
  DisplayValuePipe,
  MultipleDisplayValuePipe,
  FromEnumPipe,
  ExpiryDatetimePipe
];
@NgModule({
  declarations: pipeModules,
  providers: pipeModules,
  exports: pipeModules
})
export class ApplicationPipesModule {};
