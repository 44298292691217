/* tslint:disable */

declare var Object: any;
export interface SMSConfigurationInterface {
  "message": string;
  "code": string;
  "codeDescription"?: string;
  "hasEmail"?: boolean;
  "dateCreated"?: Date;
  "dateUpdated"?: Date;
  "product"?: string;
  "planType"?: string;
  "id"?: number;
}

export class SMSConfiguration implements SMSConfigurationInterface {
  "message": string;
  "code": string;
  "codeDescription": string;
  "hasEmail": boolean;
  "dateCreated": Date;
  "dateUpdated": Date;
  "product": string;
  "planType": string;
  "id": number;
  constructor(data?: SMSConfigurationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SMSConfiguration`.
   */
  public static getModelName() {
    return "SMSConfiguration";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SMSConfiguration for dynamic purposes.
  **/
  public static factory(data: SMSConfigurationInterface): SMSConfiguration{
    return new SMSConfiguration(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SMSConfiguration',
      plural: 'SMSConfigurations',
      path: 'SMSConfigurations',
      idName: 'id',
      properties: {
        "message": {
          name: 'message',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "codeDescription": {
          name: 'codeDescription',
          type: 'string'
        },
        "hasEmail": {
          name: 'hasEmail',
          type: 'boolean',
          default: false
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateUpdated": {
          name: 'dateUpdated',
          type: 'Date'
        },
        "product": {
          name: 'product',
          type: 'string'
        },
        "planType": {
          name: 'planType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
