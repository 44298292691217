import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import { UserDataApi } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
    intervalId = 0;
    seconds = 10;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userDataApi: UserDataApi
    ) { }

    clearTimer() { clearInterval(this.intervalId); }

    ngOnInit()    { this.startCountDown(); }
    ngOnDestroy() { this.clearTimer(); }

    // start() { this.countDown(); }

    // private verify() {
    //     console.log(this.route.snapshot.paramMap.get('token'));
    //     this.userDataApi.findOne({ where : { 'verificationToken' : this.route.snapshot.paramMap.get('token'), emailVerified : false } })
    //     // this.userDataApi.updateAll({ emailVerified: true }, { 'verificationToken': this.route.snapshot.paramMap.get('token') , emailVerified: false })
	// 	.subscribe(
	// 		response => {
	// 			console.log('success ', response);
	// 		},
	// 		error => {
	// 			console.log('ERROR: ', error.code);
	// 		}
	// 	);
    // }

    private startCountDown() {
        this.clearTimer();
        this.intervalId = window.setInterval(() => {
            this.seconds -= 1;
            if (this.seconds === 0) {
                this.clearTimer();
                // this.router.navigate(['/login']);
            }
        }, 1000);
    }
}
