/* tslint:disable */

declare var Object: any;
export interface BranchInterface {
  "name": string;
  "location": string;
  "outpatient_consultation": string;
  "outpatient_laboratory": string;
  "archived"?: boolean;
  "id"?: number;
}

export class Branch implements BranchInterface {
  "name": string;
  "location": string;
  "outpatient_consultation": string;
  "outpatient_laboratory": string;
  "archived": boolean;
  "id": number;
  constructor(data?: BranchInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Branch`.
   */
  public static getModelName() {
    return "Branch";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Branch for dynamic purposes.
  **/
  public static factory(data: BranchInterface): Branch{
    return new Branch(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Branch',
      plural: 'branches',
      path: 'branches',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "outpatient_consultation": {
          name: 'outpatient_consultation',
          type: 'string'
        },
        "outpatient_laboratory": {
          name: 'outpatient_laboratory',
          type: 'string'
        },
        "archived": {
          name: 'archived',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
