/* tslint:disable */

declare var Object: any;
export interface EmailLogInterface {
  "name": string;
  "email": string;
  "referenceNumber": string;
  "emailTo": string;
  "bcc": string;
  "dateSent": Date;
  "status"?: boolean;
  "id"?: number;
}

export class EmailLog implements EmailLogInterface {
  "name": string;
  "email": string;
  "referenceNumber": string;
  "emailTo": string;
  "bcc": string;
  "dateSent": Date;
  "status": boolean;
  "id": number;
  constructor(data?: EmailLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EmailLog`.
   */
  public static getModelName() {
    return "EmailLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EmailLog for dynamic purposes.
  **/
  public static factory(data: EmailLogInterface): EmailLog{
    return new EmailLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmailLog',
      plural: 'EmailLogs',
      path: 'EmailLogs',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "referenceNumber": {
          name: 'referenceNumber',
          type: 'string'
        },
        "emailTo": {
          name: 'emailTo',
          type: 'string'
        },
        "bcc": {
          name: 'bcc',
          type: 'string'
        },
        "dateSent": {
          name: 'dateSent',
          type: 'Date',
          default: new Date(0)
        },
        "status": {
          name: 'status',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
