import { Component } from '@angular/core';
import { BasicList } from '../../../classes';
import { ExternalApi, UserDataApi, LoopBackConfig, UserData, CustomerCardApi } from '../../../../assets/js/lb-sdk';
import { BsModalService } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { USER_ROLES } from '../../../enums';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent extends BasicList {
  params = {
    where: {}
  };
  USER_ROLES: typeof USER_ROLES = USER_ROLES;
  host:string = LoopBackConfig.getPath();
  // body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  filterForm: FormGroup;

  constructor(
    private userDataApi: UserDataApi,
    private externalApi: ExternalApi,
    private ModalService: BsModalService,
    private loopBackAuth: LoopBackAuth,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private excelService:ExcelService,
    private customerCardApi:CustomerCardApi
  ) {
    super();
    // this.body.classList.add('sidebar-mini');
    this.filterForm = this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: ''
    })
    this.settings = {
      api: externalApi,
      ModalService: ModalService,
      service: 'getMembers',
      isExternal: true
    };
  }

  reactivate(id: string) {
    this.loading.reactivate = true;
    return this.userDataApi
      .patchAttributes(id, {
        active: true
      })
      .pipe(finalize(() => {
        this.loading.reactivate = false;
      }))
      .subscribe(
        (response: any) => {
          this.getList();
        },
        error => {
          this.errors.reactivate = error.message;
        }
      );
  }

  filter() {
    this.f.firstName.value ? this.params['FirstName'] = this.f.firstName.value : delete this.params['FirstName'];
    this.f.lastName.value ? this.params['LastName'] = this.f.lastName.value : delete this.params['LastName'];
    this.getList();
  }

  get f() { return this.filterForm.controls; }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

  csv() {
    this.f.firstName.value ? this.params['FirstName'] = this.f.firstName.value : delete this.params['FirstName'];
    this.f.lastName.value ? this.params['LastName'] = this.f.lastName.value : delete this.params['LastName'];
    
    this.getListForExport((data) => {
      // console.log(list);

      let list = [];
      for(const i in data) {
        console.log(data[i]);
        list.push({
          MemberID: data[i].MemberID,
          FirstName: data[i].FirstName,
          MiddleName: data[i].MiddleName,
          LastName: data[i].LastName,
          ExtensionName: data[i].ExtensionName,
          Birthdate: data[i].Birthdate,
          Gender: data[i].Gender
        });
      }

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        headers: ["Member ID", "First Name", "Middle Name", "Last Name", "Ext", "Birth Date", "Gender"]
      };
       
      new Angular5Csv(list, 'Members', options);
    });
    
    
  }

  excel() {
    const data: any = [{
      eid: 'e101',
      ename: 'ravi',
      esal: 1000
    },
    {
      eid: 'e102',
      ename: 'ram',
      esal: 2000
    },
    {
      eid: 'e103',
      ename: 'rajesh',
      esal: 3000
    }];

    this.excelService.exportAsExcelFile(data, 'Members');
  }

  resendReferenceNumber(card) {
    const conf = confirm("Are you sure you want to resend this reference number?");
    if(conf) {
      console.log(card);
      this.loading.reactivate = true;
      this.customerCardApi
        .resendReferenceNumber({ referenceNumber : card.ReferenceNumber })
        .pipe(finalize(() => {
          this.loading.reactivate = false;
        }))
        .subscribe(
          this.notifyReferenceNumber,
          this.notifyReferenceNumberError
        );
    }
  }

  resendVoucher(card) {
    const conf = confirm("Are you sure you want to resend the voucher of this reference number?");
    if(conf) {
      console.log(card);
      this.loading.reactivate = true;
      this.customerCardApi
        .resendVoucher({ referenceNumber : card.ReferenceNumber })
        .pipe(finalize(() => {
          this.loading.reactivate = false;
        }))
        .subscribe(
          this.notifySentECard,
          this.notifySentECardError
        );
    }
  }

  notifySuccess(message,title,logTitle,data) {
    console.log(logTitle,data);
    this.toast.success(title,message);

  }

  notifyReferenceNumber(data) {
    const email = data.response.customer_email;
    this.notifySuccess(
      `Reference Number was sent to ${email}`,'Success!',
      'data',data)

  }
  notifyReferenceNumberError(error) {
    this.notifySuccess(
      'Sending Reference Number to failed.','ERROR!',
      'error',error)

  }

  notifySentECard(data) {
    const email = data.response.customer_email;
    this.notifySuccess(
      `eCard was sent to ${email}`,'Success!',
      'data',data)  
  }
  notifySentECardError(error) {
    this.notifySuccess(
      'Sending eCard to failed.','ERROR!',
      'error',error)

  }
  


}
