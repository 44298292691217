import { Component, OnInit, TemplateRef } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';

import { LoopBackConfig } from '../../../../assets/js/lb-sdk';
import { LoopBackAuth, EmailTemplateApi } from '../../../../assets/js/lb-sdk/services';
import { USER_ROLES } from '../../../enums';
// import { BasicList } from '../../../classes';

import { BsModalService, AlertModule } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';

import { EmailFormComponent } from './email-form/email-form.component';

import { Pagination } from '../../../interfaces';

interface Loader {
  [key: string]: boolean
};

interface Errors {
  [key: string]: string
};

@Component({
    selector: 'app-emails',
    templateUrl: './emails.component.html',
    styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {
    USER_ROLES: typeof USER_ROLES = USER_ROLES;
    host:string = LoopBackConfig.getPath();

    filterForm: FormGroup;
    name: FormControl;
    archived: FormControl;

    // pagination: any = {};
    // error: string;
    // loading: boolean = false;

    deleteModal: BsModalRef;
    emailTemplateData: any;

    pagination: Pagination = {
      page: 1,
      limit: 10,
      total: 0
    };
    loading: Loader = {
      list: false,
      delete: false
    };
    list: any;
    templates: any;
    settings: any;

    errors: Errors = {};
    modal: any;

    constructor(
        private loopBackAuth: LoopBackAuth,
        private emailTemplate: EmailTemplateApi,
        private ModalService: BsModalService,
        private toast: ToastrService,
    ) {
        // super();
        this.name = new FormControl('', [Validators.required, Validators.minLength(1)]);
        this.archived = new FormControl('', Validators.required);

        this.filterForm = new FormGroup({
            name: this.name,
            archived: this.archived
        });

        this.settings = {
            createComponent: EmailFormComponent,
            ModalService: ModalService
        };
    }

    ngOnInit() {
        this.getList();
    }

    getUser() {
        return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }

    getList() {
        console.log(this.pagination);
        // this.loading.list = true;
        this.loading.product = true;
        // console.log(this.name.value);
        // console.log(this.archived.value);
        let where = {};
        if(this.name.value.replace(/\s/g,'')){
            where['name'] = { like : ["%",this.name.value,"%"].join("") };
        }
        if(this.archived.value) {
            where['archived'] = (this.archived.value=="active")?'false':'true';
        }
        // console.log(where);

        this.emailTemplate.find({ where : where })
        .subscribe(
            response => {
                // this.loading.list = false;
                this.loading.product = false;

                this.list = this.paginate(response);
                this.pagination.total = response.length;
            },
            error => {
                // this.loading.list = false;
                this.loading.product = false;
                this.errors.products = error.message;
                // this.list = [];
            }
        );
    }

    edit(data) {
        console.log(data);
        this.modal = this.ModalService
        .show(EmailFormComponent, {
            initialState: {
                data: data
            },
            class: 'custom-width'
        });

        this.modal.content.action.take(1)
            .subscribe((value) => {
                console.log(this.list);
                console.log('edited', value);

                for(const i in this.list) {
                    if(this.list[i].id == value.id) {
                        this.list[i].name = value.name;
                        this.list[i].description = value.description;
                        this.list[i].template = value.template;
                    }
                }

            }, (err) => {
            
            }
        );
    }


    activateDeactivate() {
        this.emailTemplateData.archived = !this.emailTemplateData.archived;
        this.emailTemplate.upsert(this.emailTemplateData)
        .subscribe(
            response => {
                console.log(response);
                this.deleteModal.hide();
                this.toast.success(
                    [this.emailTemplateData.name,
                    ' was successfully ',
                    (this.emailTemplateData.archived)?' deactivated.':' activated.'].join("")
                    , 'Success!'
                );
            },
            error => {
                this.deleteModal.hide();
                this.toast.error(error.message, 'Error!');
            }
        );
    }

    openDeleteModal(template: TemplateRef<any>, data) {
        this.emailTemplateData = data;
        this.deleteModal = this.ModalService.show(template, { class: 'modal-sm' });
    }
    openActivateModal(template: TemplateRef<any>, data) {
        this.emailTemplateData = data;
        this.deleteModal = this.ModalService.show(template, { class: 'modal-sm' });
    }

    hasError():boolean {
        return Object.keys(this.errors).reduce((hasError, key):boolean => {
            return hasError || !!this.errors[key];
        }, false);
    }

    isLoading():boolean {
        return Object.keys(this.loading).reduce((loading, key):boolean => {
            return loading || this.loading[key];
        }, false);
    }

    paginate(data) {
        const result = data.filter((tmpl,key) => {
            if(this.pagination.page == 1){
                return key < this.pagination.limit;
            }
            else {
                return key < this.pagination.limit * this.pagination.page && key >= this.pagination.limit * (this.pagination.page - 1);
            }
        });

        return result;
    }

}
