import { NgModule, Component, ViewChild, OnInit, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { SMSConfigurationApi } from '../../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoopBackAuth } from '../../../../../assets/js/lb-sdk/services';

interface SmsForm {
  id?: string,
  message?: string
};

@Component({
  selector: 'app-sms-form',
  templateUrl: './sms-form.component.html',
  styleUrls: ['./sms-form.component.css']
})
export class SMSFormComponent implements OnInit {

  smsForm: FormGroup;
  data?;
  submitted = false;

  error: string;
  loading: boolean = false;
	emailValid: boolean = true;

  roles = [];

  constructor(
    public modal: BsModalRef,
    private formBuilder: FormBuilder,
    private smsConfiguration: SMSConfigurationApi,
    private toastr: ToastrService,
    private loopBackAuth: LoopBackAuth
  ) { }

  ngOnInit() {
    this.data = this.data || {};
    this.smsForm = this.formBuilder.group({
      message: ['', Validators.required],
      description: ['']
    });
    this.smsForm.setValue({
      message: this.data.message,
      description: this.data.codeDescription
    });
  }

  dismiss(reason: string) {
    this.modal.content.reason = reason;
    this.modal.hide();
  }

  close(message) {
    this.modal.content.result = message;
    this.modal.hide();
  }

  isLoading(): boolean {
    return this.loading
  }

  submit() {
    this.submitted = true;
    if (this.smsForm.invalid) {
      return;
    }
    this.loading = true;
    const data = {
      message: this.f.message.value
    }
    this.smsConfiguration
      .patchAttributes(this.data.id, data)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        data => {
          this.toastr.success(this.f.description.value + ' is successfully added.', 'SUCCESS');
          this.close(data);
        },
        error => {
          this.toastr.error(error.message, 'ERROR');
        }
      );
  }

  get f() { return this.smsForm.controls; }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }
}
