export class LoadingAndErrors {
  loading: { [key:string]: boolean } = {};
  errors: { [key:string]: string } = {};

  isLoading():boolean {
    return Object.keys(this.loading).reduce((loading, key):boolean => {
      return loading || this.loading[key];
    }, false);
  }

  hasError():boolean {
    return Object.keys(this.errors)
      .reduce((hasError:boolean, key: string):boolean => {
        return hasError || !!this.errors[key];
      }, false);
    }
}
