import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {

  transform(roles: Array<any>, role: number, condition?: string, anotherRole?: number, arr?: Array<any>): any {
    return (roles || []).reduce((hasRole, r) => {
      if (condition) {
        switch (condition.toLowerCase().trim()) {
          case 'less than':
          case '<':
            return hasRole || r && r.role && r.role.code && r.role.code < role;
          case 'greater than':
          case '>':
            return hasRole || r && r.role && r.role.code && r.role.code > role;
          case 'between':
          case '><':
            return hasRole || r && r.role && r.role.code && ((r.role.code >= role) && (r.role.code <= anotherRole));
          case 'or':
            return hasRole || r && r.role && r.role.code && arr.indexOf(r.role.code) !== -1;
          default:
            console.error('Not a valid has role condition: ' + condition);
            return hasRole || false;
        }
      }
      return hasRole || r && r.role && r.role.code && r.role.code == role;
    }, false);
  }

}