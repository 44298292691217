import { Component, OnInit } from '@angular/core';

import { LoopBackAuth, UserDataApi } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-home-content',
    templateUrl: './home-content.component.html',
    styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit {
    carousel:Array<boolean> = [
        true,
        false,
        false,
        false
    ];

    constructor(private loopBackAuth: LoopBackAuth) { }

    ngOnInit() {

    }

    next(to) {
        for (const i of Object.keys(this.carousel)) {
            this.carousel[i] = false;
        }
        this.carousel[to] = true;
    }

    getUser() {
        return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
    }

}
