import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  message: string;
  deleteText: string;
  closeText: string;
  title: string;

  constructor(public modal: BsModalRef) {
  }

  ngOnInit() {
  }

  dismiss(reason: string) {
    this.modal.content.reason = reason;
    this.modal.hide();
  }

  close(result: any) {
    this.modal.content.result = result || '';
    this.modal.hide();
  }
}
