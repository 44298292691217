import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-renewal-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.css']
})
export class RenewalExpiredComponent implements OnInit {
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  url = environment.shop;
  constructor() { }
  ngOnInit() {
    this.body.classList.remove('sidebar-mini');
    this.body.classList.add('skin-blue');
    this.body.classList.add('layout-top-nav');
  }
}
