import { NgModule, Component, ViewChild, OnInit, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UserDataApi, RoleDataApi } from '../../../../../assets/js/lb-sdk';
import { finalize } from 'rxjs/operators';
import { USER_ROLES } from '../../../../enums';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoopBackAuth } from '../../../../../assets/js/lb-sdk/services';

interface RegisterForm {
  id?: string,
  role?: string,
  firstName?: string,
  lastName?: string,
  email?: string
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  registerForm: FormGroup;
  
  userRoles = USER_ROLES;
  error: string;
  loading: boolean = false;
  emailValid: boolean = true;
  data: RegisterForm;
  fetching: boolean = false;
  
  roles = [];

  REGEX = {
    USERNAME: /^[a-zA-Z]+[a-zA-Z0-9_]*$/,
    USERCODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)])[A-Za-z\d$@$!%*?&#;:<>,.`~_\-\+\[\]\\\\/\|\{\}\'\"\=\^\(\)]{8,}$/
  };

  constructor(
    public modal: BsModalRef,
    private formBuilder: FormBuilder,
    private userDataApi: UserDataApi,
    private roleDataApi: RoleDataApi,
    private toastr: ToastrService,
    private loopBackAuth: LoopBackAuth
  ) { }

  ngOnInit() {
    this.loading = true;
    this.registerForm = this.formBuilder.group({
        role: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, 
          Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'),
            (control: FormControl) => {
              if(!this.emailValid){
                return {
                  mismatch: true
                }
              }
              return null
            }
        ]],
    });
    this.roleDataApi.find()
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        (response: any) => {
          if (response.length) {
            this.roles = response.filter((role) => {
              return ['MEMBER'].indexOf(role.name) < 0;
            })
          }
        },
        error => {
          console.log('ERROR: ', error.code);
        }
      );
      if (this.data && this.data.id) {
        this.registerForm.patchValue({
          role: this.data.role,
          firstName: this.data.firstName,
          lastName: this.data.lastName,
          email: this.data.email
        });
      }
  }

  dismiss(reason: string) {
    this.modal.content.reason = reason;
    this.modal.hide();
  }

  close(message) {
    this.modal.content.result = message;
    this.modal.hide();
  }

  isLoading(): boolean {
    return this.loading
  }

  validateEmailAddress(email) {
      this.fetching = true;
      this.userDataApi.search({ 'where' : { adminUserOnly: true, email : email.value }})
      .pipe(finalize(() => {
        this.fetching = false;
      }))
      .subscribe(
        response => {
          if (response.total > 0) {
            email.setErrors({ existing: true })
          } else {
            email.existing = null;
          }
        },
        error => {
          console.log('ERROR: ', error.code);
        }
      );
  }
  
  submit() {
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.registerForm.markAsTouched({onlySelf: false});
      return;
    }
    this.loading = true;
    const data = {
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      email: this.f.email.value,
      role: this.f.role.value
    }
    if (!this.data) {
      this.userDataApi
        .register(data)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          data => {
            this.toastr.success(this.f.firstName.value + ' is successfully added.', 'SUCCESS');
            this.close(data);
          },
          error => {
            this.toastr.error(error.message, 'ERROR');
          }
        );
    } else {
      // delete data.role;
      this.userDataApi
        .updateUser(this.data.id, data)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          data => {
            this.toastr.success(this.f.firstName.value + ' is successfully updated.', 'SUCCESS');
            this.close(data);
          },
          error => {
            this.toastr.error(error.message, 'ERROR');
          }
        );
    }
  }

  get f() { return this.registerForm.controls; }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

  emailAddressOnChange() {
    if(this.f.email.valid) {
      this.validateEmailAddress(this.f.email);
    }
	}

}
