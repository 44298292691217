import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector : '[appNumberOnly]'
})
export class NumberOnlyDirective {
  // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow decimal numbers
  private regex: RegExp = new RegExp(/^[+]?([.]\d+|\d+([.]\d+)?)$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-', 'Enter', 'ArrowLeft', 'ArrowRight', 'Control' ];

  constructor(private el: ElementRef) {

  }
  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
    console.log('this event ', event);
    // Allow Backspace, tab, end, and home keys
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      ((event.keyCode == 65 || event.keyCode == 86 || event.keyCode == 67) && (event.ctrlKey === true || event.metaKey === true))
    ) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
