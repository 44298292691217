import { Component } from '@angular/core';
import { BasicList } from '../../../classes';
import { Event } from '../../../interfaces';
import { ToastrService } from 'ngx-toastr';
import { AuditTrailApi } from '../../../../assets/js/lb-sdk/services/custom/AuditTrail';
import { AuditTrailsDetailsComponent } from './audit-trails-details/audit-trails-details.component';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-audit-trails',
  templateUrl: './audit-trails.component.html',
  styleUrls: ['./audit-trails.component.css']
})
export class AuditTrailsComponent extends BasicList {
  searchSettings = {
    searchFields: [],
    searchableFields: [
      { key: 'module', placeholder: 'Module', wildcard: true },
      { key: 'endpoint', placeholder: 'Endpoint', wildcard: true },
      { key: 'method', placeholder: 'Method', optionsFunction: () => {
        return [
          { value: 'GET', description: 'GET' },
          { value: 'POST', description: 'POST' },
          { value: 'PUT', description: 'PUT' },
          { value: 'PATCH', description: 'PATCH' },
          { value: 'DELETE', description: 'DELETE' }
        ]
      } },
      { key: 'error', placeholder: 'Response', optionsFunction: () => {
        return [
          { value: '--null--', description: 'SUCCESS' },
          { value: { neq: null }, description: 'FAILED' }
        ]
      } }
    ],
    filters: {}
  };

  constructor(
    private auditTrailApi: AuditTrailApi,
    private toast: ToastrService,
    private modalService: BsModalService
  ) {
    super();
    this.searchSettings.searchFields.push(this.searchSettings.searchableFields[0]);
    this.params = {
      where: {},
      include: 'user',
      order: 'datetime DESC'
    };
    this.settings = {
      createComponent: undefined,
      api: this.auditTrailApi,
      ModalService: undefined
    };
  }

  details(data) {
    this.modalService
      .show(AuditTrailsDetailsComponent, {
        initialState: {
          data: data
        }
      });
  }
}
