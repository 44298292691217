import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
	selector: 'modal-content',
    templateUrl: './register-modal.component.html',
    styleUrls: ['./register-modal.component.css']
  })
   
  export class RegisterModalComponent implements OnInit {
	title: string;
   
	constructor(public bsModalRef: BsModalRef) {}
   
	ngOnInit() {

	}
  }