import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayValue'
})
export class DisplayValuePipe implements PipeTransform {
  transform(value, list: Array<any>, key: string, display: string): string {
    return ((Array.isArray(list) ? list : []).filter(data => {
        return data[key || 'id'] == value;
      })[0] || {}
    )[display || 'name'] || value;
  }
}
