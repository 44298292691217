/* tslint:disable */

declare var Object: any;
export interface StorageManagerInterface {
  "id"?: number;
}

export class StorageManager implements StorageManagerInterface {
  "id": number;
  constructor(data?: StorageManagerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StorageManager`.
   */
  public static getModelName() {
    return "StorageManager";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StorageManager for dynamic purposes.
  **/
  public static factory(data: StorageManagerInterface): StorageManager{
    return new StorageManager(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StorageManager',
      plural: 'StorageManagers',
      path: 'StorageManagers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
