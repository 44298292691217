/* tslint:disable */
import {
  UserData,
  Province,
  Municipality
} from '../index';

declare var Object: any;
export interface CustomerInfoInterface {
  "UserDataId": number;
  "firstName": string;
  "middleName"?: string;
  "lastName"?: string;
  "extension"?: string;
  "birthDate": Date;
  "gender": number;
  "civilStatus": number;
  "houseNumber"?: string;
  "street"?: string;
  "province"?: number;
  "municipality"?: number;
  "zipCode"?: number;
  "mobileNumber": string;
  "emailAddress": string;
  "image"?: string;
  "id"?: number;
  user?: UserData;
  provinceId?: Province;
  municipalityId?: Municipality;
}

export class CustomerInfo implements CustomerInfoInterface {
  "UserDataId": number;
  "firstName": string;
  "middleName": string;
  "lastName": string;
  "extension": string;
  "birthDate": Date;
  "gender": number;
  "civilStatus": number;
  "houseNumber": string;
  "street": string;
  "province": number;
  "municipality": number;
  "zipCode": number;
  "mobileNumber": string;
  "emailAddress": string;
  "image": string;
  "id": number;
  user: UserData;
  provinceId: Province;
  municipalityId: Municipality;
  constructor(data?: CustomerInfoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomerInfo`.
   */
  public static getModelName() {
    return "CustomerInfo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CustomerInfo for dynamic purposes.
  **/
  public static factory(data: CustomerInfoInterface): CustomerInfo{
    return new CustomerInfo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomerInfo',
      plural: 'CustomerInfos',
      path: 'CustomerInfos',
      idName: 'id',
      properties: {
        "UserDataId": {
          name: 'UserDataId',
          type: 'number'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "middleName": {
          name: 'middleName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "extension": {
          name: 'extension',
          type: 'string'
        },
        "birthDate": {
          name: 'birthDate',
          type: 'Date'
        },
        "gender": {
          name: 'gender',
          type: 'number'
        },
        "civilStatus": {
          name: 'civilStatus',
          type: 'number'
        },
        "houseNumber": {
          name: 'houseNumber',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "province": {
          name: 'province',
          type: 'number'
        },
        "municipality": {
          name: 'municipality',
          type: 'number'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'number'
        },
        "mobileNumber": {
          name: 'mobileNumber',
          type: 'string'
        },
        "emailAddress": {
          name: 'emailAddress',
          type: 'string'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserData',
          model: 'UserData',
          relationType: 'belongsTo',
                  keyFrom: 'UserDataId',
          keyTo: 'id'
        },
        provinceId: {
          name: 'provinceId',
          type: 'Province',
          model: 'Province',
          relationType: 'belongsTo',
                  keyFrom: 'province',
          keyTo: 'id'
        },
        municipalityId: {
          name: 'municipalityId',
          type: 'Municipality',
          model: 'Municipality',
          relationType: 'belongsTo',
                  keyFrom: 'municipality',
          keyTo: 'id'
        },
      }
    }
  }
}
