// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // api: 'http://ec2-13-228-168-204.ap-southeast-1.compute.amazonaws.com',
  // api: 'http://ec2-54-254-154-87.ap-southeast-1.compute.amazonaws.com',
  // api: 'http://localhost:3000',
  // api: '127.0.0.1:3000',
  api: '',
  shop: 'https://shop.maxicare.com.ph',
  magentoMediaLocation: '/pub/media/catalog/product',
  version: 'api'
};
