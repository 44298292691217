import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-expired',
  templateUrl: './page-expired.component.html',
  styleUrls: ['./page-expired.component.css']
})
export class PageExpiredComponent implements OnInit {
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  constructor() { }

  ngOnInit() {
    this.body.classList.remove('sidebar-mini');
    this.body.classList.add('skin-blue');
    this.body.classList.add('layout-top-nav');
  }

}
