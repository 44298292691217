import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';

@Component({
  selector: 'app-main-left-side',
  templateUrl: './main-left-side.component.html',
  styleUrls: ['./main-left-side.component.css']
})
export class MainLeftSideComponent implements OnInit {

  constructor(private loopBackAuth: LoopBackAuth) { }

  ngOnInit() {

  }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

}
