import { Component } from '@angular/core';
import { BasicList } from '../../../classes';
import { UserDataApi, LoopBackConfig, UserData } from '../../../../assets/js/lb-sdk';
import { BsModalService } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { UserFormComponent } from './user-form/user-form.component';
import { ChangePasswordComponent } from '../authentication/change-password/change-password.component';
import { ChangeUsernameComponent } from '../authentication/change-username/change-username.component';
import { ToastrService } from 'ngx-toastr';
import { USER_ROLES } from '../../../enums';
import { LoopBackAuth } from '../../../../assets/js/lb-sdk/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BasicList {
  params = {
    where: {
      adminUserOnly: true
    }
  };
  USER_ROLES: typeof USER_ROLES = USER_ROLES;
  host:string = LoopBackConfig.getPath();
  // body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  filterForm: FormGroup;

  constructor(
    private userDataApi: UserDataApi,
    private ModalService: BsModalService,
    private loopBackAuth: LoopBackAuth,
    private toast: ToastrService,
    private formBuilder: FormBuilder
  ) {
    super();
    // this.body.classList.add('sidebar-mini');
    this.filterForm = this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: ''
    })
    this.settings = {
      createComponent: UserFormComponent,
      api: userDataApi,
      ModalService: ModalService,
      delete: {
        method: this.deleteMethod.bind(this),
        title: 'Confirm Deactivate',
        deleteText: 'Deactivate'
      },
      search: true
    };
  }
  
  reactivate(id: string) {
    this.loading.reactivate = true;
    return this.userDataApi
      .patchAttributes(id, {
        active: true
      })
      .pipe(finalize(() => {
        this.loading.reactivate = false;
      }))
      .subscribe(
        (response: any) => {
          this.getList();
        },
        error => {
          this.errors.reactivate = error.message;
        }
      );
  }

  // changeUsername(user: UserData) {
  //   const changeUserNameModal = this.ModalService
  //   .show(ChangeUsernameComponent, {
  //     initialState: {
  //       data: {
  //         id: user.id,
  //         username: user.username
  //       }
  //     }
  //   });
  //   const subscription = this.ModalService.onHidden
  //     .subscribe(() => {
  //       if (changeUserNameModal.content) {
  //         if (changeUserNameModal.content.result) {
  //           this.toast.success('Successfully updated username!', 'Update Success');
  //         }
  //       }
  //       subscription.unsubscribe();
  //     });
  // }

  changePassword(user: UserData) {
    const changePasswordModal = this.ModalService
      .show(ChangePasswordComponent, {
        initialState: {
          data: { id: user.id }
        }
      });
    const subscription = this.ModalService.onHidden
      .subscribe(() => {
        if (changePasswordModal.content) {
          if (changePasswordModal.content.result) {
            this.toast.success('Successfully updated password!', 'Update Success');
          }
        }
        subscription.unsubscribe();
      });
  }

  deleteMethod(id: string) {
    return this.userDataApi
      .patchAttributes(id, {
        active: false
      });
  }

  getUser() {
    return this.loopBackAuth.getToken() ? this.loopBackAuth.getCurrentUserData() : undefined;
  }

  filter() {
    this.f.firstName.value ? this.params.where['firstName'] = this.f.firstName.value : delete this.params.where['firstName'];
    this.f.lastName.value ? this.params.where['lastName'] = this.f.lastName.value : delete this.params.where['lastName'];
    this.f.email.value ? this.params.where['email'] = this.f.email.value : delete this.params.where['email'];
    if (!this.f.firstName.value && !this.f.lastName.value && !this.f.email.value) {
      this.params.where = { adminUserOnly: true };
    }
    this.getList();
  }

  get f() { return this.filterForm.controls; }

}
