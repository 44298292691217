import { NgModule, Component, Pipe, OnInit } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Md5 } from 'ts-md5/dist/md5';
import { CookieService } from 'ngx-cookie-service';

import { UserDataApi, LoopBackAuth } from '../../../../../assets/js/lb-sdk/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    loginForm: FormGroup;

    username: FormControl;
    password: FormControl;
    rememberMe: FormControl;

    error?: string = null;

    constructor(
        private userDataApi: UserDataApi,
        private loopBackAuth: LoopBackAuth,
        private router: Router,
        private cookieService: CookieService
    ) { }

    ngOnInit() {
        this.createFormControls();
        this.createForm();
    }

    createFormControls() {
        // this.username = new FormControl('', Validators.required);
        this.username = new FormControl('', [
            Validators.required,
            Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')
        ]);
        this.password = new FormControl('', Validators.required);
        this.rememberMe = new FormControl(false, []);
    }

    createForm() {
        this.loginForm = new FormGroup({
            username: this.username,
            password: this.password,
            rememberMe: this.rememberMe
        });
    }

    checkForm() {
        let ret = true;
        if (
            !this.username.value || !this.password.value ||
            (this.username.errors && (this.username.dirty || this.username.touched)) ||
            (this.password.errors && (this.password.dirty || this.password.touched))
        ) {
            ret = false;
        }

        return ret;
    }

    onLogin() {
        const data = {
            username : this.username.value,
            password : this.password.value,
            rememberMe: this.rememberMe.value
        };

        if (data.username && data.password) {
            this.error = undefined;

            this.userDataApi.login({
                username: data.username,
                password: data.password
            }, undefined, data.rememberMe)
            .subscribe(
                response => {
                    console.log('LOGIN', response);
                    this.loopBackAuth.setRememberMe(data.rememberMe);
                    this.loopBackAuth.setToken(response);
                    this.userDataApi
                    .findById(response.userId, { include: { roles: 'role' } })
                    .subscribe(user => {
                        this.loopBackAuth.setUser(user);
                    });
                    this.router.navigateByUrl('/profile');
                },
                error => {
                    switch (error.code) {
                        case 'LOGIN_FAILED':
                        this.error = 'Invalid Credentials.';
                        break;
                        case 'UNVERIFIED_EMAIL':
                        this.error = 'The email address associated to this account is not yet verified.';
                        break;
                        default:
                        this.error = 'Unable to login,';
                        break;
                    }

                }
            );
        }
    }
}
