import { Component, OnInit } from '@angular/core';
import { UserDataApi } from '../../../../../assets/js/lb-sdk';
import { LoadingAndErrors } from '../../../../classes';
import { finalize } from 'rxjs/operators';

interface ForgotPasswordForm {
  email: string
};

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends LoadingAndErrors implements OnInit {
  forgotPassword: ForgotPasswordForm = {
    email: undefined
  };
  success: boolean;

  body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  constructor(
    private userDataApi: UserDataApi
  ) {
    super();
  }

  ngOnInit() {
    // add the body classes
    this.body.classList.add('skin-blue');
    this.body.classList.add('layout-top-nav');
    this.body.classList.remove('sidebar-collapse');
    this.body.classList.remove('sidebar-mini');
  }

  submit(form) {
    if (form.valid) {
      this.loading.form = true;
      this.userDataApi
        .resetPassword(this.forgotPassword)
        .pipe(finalize(() => {
          this.loading.form = false;
        }))
        .subscribe(
          () => {
            this.success = true;
          },
          error => {
            this.errors.forgot = error.message;
          }
        );
    }
  }

}
