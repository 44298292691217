/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { RoleDataApi } from './services/custom/RoleData';
import { UserDataApi } from './services/custom/UserData';
import { UserRoleApi } from './services/custom/UserRole';
import { CustomerInfoApi } from './services/custom/CustomerInfo';
import { CustomerCardApi } from './services/custom/CustomerCard';
import { GenderApi } from './services/custom/Gender';
import { CivilStatusApi } from './services/custom/CivilStatus';
import { ProvinceApi } from './services/custom/Province';
import { MunicipalityApi } from './services/custom/Municipality';
import { EmailApi } from './services/custom/Email';
import { EmailTemplateApi } from './services/custom/EmailTemplate';
import { SmsApi } from './services/custom/Sms';
import { ConfigurationApi } from './services/custom/Configuration';
import { SMSConfigurationApi } from './services/custom/SMSConfiguration';
import { ProductApi } from './services/custom/Product';
import { CronJobApi } from './services/custom/CronJob';
import { ExternalApi } from './services/custom/External';
import { StorageManagerApi } from './services/custom/StorageManager';
import { OtpApi } from './services/custom/Otp';
import { BranchApi } from './services/custom/Branch';
import { MemberApi } from './services/custom/Member';
import { EmailLogApi } from './services/custom/EmailLog';
import { RenewalApi } from './services/custom/Renewal';
import { PaymentApi } from './services/custom/Payment';
import { OrderApi } from './services/custom/Order';
import { OrderItemApi } from './services/custom/OrderItem';
import { OrApi } from './services/custom/Or';
import { GenerateCardReferenceLogApi } from './services/custom/GenerateCardReferenceLog';
import { AuditTrailApi } from './services/custom/AuditTrail';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        RoleDataApi,
        UserDataApi,
        UserRoleApi,
        CustomerInfoApi,
        CustomerCardApi,
        GenderApi,
        CivilStatusApi,
        ProvinceApi,
        MunicipalityApi,
        EmailApi,
        EmailTemplateApi,
        SmsApi,
        ConfigurationApi,
        SMSConfigurationApi,
        ProductApi,
        CronJobApi,
        ExternalApi,
        StorageManagerApi,
        OtpApi,
        BranchApi,
        MemberApi,
        EmailLogApi,
        RenewalApi,
        PaymentApi,
        OrderApi,
        OrderItemApi,
        OrApi,
        GenerateCardReferenceLogApi,
        AuditTrailApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

