import { OnInit } from '@angular/core';
import { Pagination, Event } from '../interfaces';
import { finalize } from 'rxjs/operators';
import { DeleteModalComponent } from '../templates/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap';

interface Loader {
  [key: string]: boolean
};

interface Errors {
  [key: string]: string
};

export interface DeleteSettings {
  method: Function,
  title?: string,
  deleteText?: string,
  closeText?: string
}

export interface BasicListSettings {
  createComponent?;
  editComponent?;
  api;
  ModalService?: BsModalService;
  delete?: DeleteSettings;
  isExternal?;
  service?;
  search?;
}

export class BasicList implements OnInit {
  params = {};
  settings: BasicListSettings;
  pagination: Pagination = {
    page: 1,
    limit: 10,
    total: 0
  };
  loading: Loader = {
    list: false,
    delete: false
  };
  errors: Errors = {};
  list: Array<any> = [];
  exportList: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.emitEvent('initialized');
  }

  getList(): void {
    if (this.loading.list) {
      return;
    }
    this.emitEvent('getList:started');
    this.loading.list = true;
    if (!this.settings.api) {
      this.loading.list = false;
      this.errors.list = 'Api property is not found in settings of class BasicList.'
      this.emitEvent('getList:failed');
      this.emitEvent('getList:ended');
      return;
    }
    if (this.settings.isExternal) {
      this.settings.api[this.settings.service]({
          ...this.params,
          offset: this.pagination.limit * (this.pagination.page - 1),
          limit: this.pagination.limit
        })
        .pipe(finalize(() => {
          this.loading.list = false;
          this.emitEvent('getList:ended');
        }))
        .subscribe(
          (response: any) => {
            // console.log('response', response);
            this.emitEvent('getList:success');
            this.list = response.data;
            this.pagination.total = response.total;
          },
          error => {
            this.emitEvent('getList:failed');
            this.errors.list = error.message;
          }
        );
    } else {
      const serve = this.settings.search ? 'search' : 'paginate';
      this.settings.api[serve]({
          ...this.params,
          offset: this.pagination.limit * (this.pagination.page - 1),
          limit: this.pagination.limit
        })
        .pipe(finalize(() => {
          this.loading.list = false;
          this.emitEvent('getList:ended');
        }))
        .subscribe(
          (response: any) => {
            console.log('response', response);
            this.emitEvent('getList:success');
            this.list = response.data;
            this.pagination.total = response.total;
          },
          error => {
            this.emitEvent('getList:failed');
            this.errors.list = error.message;
          }
        );
    }
  }

  getListForExport(next): void {
    if (this.loading.list) {
      return;
    }
    this.emitEvent('getList:started');
    this.loading.list = true;
    if (!this.settings.api) {
      this.loading.list = false;
      this.errors.list = 'Api property is not found in settings of class BasicList.'
      this.emitEvent('getList:failed');
      this.emitEvent('getList:ended');
      return;
    }
    if (this.settings.isExternal) {
      this.settings.api[this.settings.service]({
          ...this.params,
          offset: 0,
          limit: 0
        })
        .pipe(finalize(() => {
          this.loading.list = false;
          this.emitEvent('getList:ended');
        }))
        .subscribe(
          (response: any) => {
            console.log('response', response);
            this.emitEvent('getList:success');
            this.exportList = response.data;
            next(response.data);
          },
          error => {
            this.emitEvent('getList:failed');
            this.errors.list = error.message;
          }
        );
    } else {
      const serve = this.settings.search ? 'search' : 'paginate';
      this.settings.api[serve]({
          ...this.params,
          offset: this.pagination.limit * (this.pagination.page - 1),
          limit: this.pagination.limit
        })
        .pipe(finalize(() => {
          this.loading.list = false;
          this.emitEvent('getList:ended');
        }))
        .subscribe(
          (response: any) => {
            this.emitEvent('getList:success');
            this.exportList = response.data;
            next(response.data);
          },
          error => {
            this.emitEvent('getList:failed');
            this.errors.list = error.message;
          }
        );
    }
  }

  delete(id: any, message: string): void {
    if (this.loading.delete) {
      return;
    }
    const deleteModal = this.settings.ModalService
      .show(DeleteModalComponent, {
        initialState: {
          message: message,
          title: this.settings.delete && this.settings.delete.title,
          deleteText: this.settings.delete && this.settings.delete.deleteText,
          closeText: this.settings.delete && this.settings.delete.closeText,
          reason: undefined,
          result: undefined
        }
      });
    this.emitEvent('deleteModal:opened');
    const subscription = this.settings.ModalService.onHidden
      .subscribe(() => {
        if (deleteModal.content.result) {
          this.emitEvent('deleteModal:completed', deleteModal.content.result);
          this.emitEvent('delete:started');
          this.loading.delete = true;
          if (!this.settings.api) {
            this.loading.delete = false;
            this.errors.delete = 'Api property is not found in settings of class BasicList.'
            this.emitEvent('delete:failed');
            this.emitEvent('delete:ended');
            return;
          }
          (this.settings.delete && this.settings.delete.method ?
            this.settings.delete.method(id) :
            this.settings.api.deleteById(id)
          ).pipe(finalize(() => {
              this.loading.delete = false;
              this.emitEvent('delete:ended');
            }))
            .subscribe(
              (response: any) => {
                this.emitEvent('delete:success');
              },
              error => {
                this.emitEvent('delete:failed');
                this.errors.delete = error.message;
              }
            );
        } else {
          this.emitEvent('deleteModal:closed', deleteModal.content.reason);
        }
        subscription.unsubscribe();
      });
  }

  new(data): void {
    const createModal = this.settings.ModalService
      .show(this.settings.createComponent, {
        initialState: { data }
      });
    this.emitEvent('createModal:opened');
    const subscription = this.settings.ModalService.onHidden
      .subscribe(() => {
        if (createModal.content) {
          if (createModal.content.result) {
            this.emitEvent('createModal:completed', createModal.content.result);
          } else {
            this.emitEvent('createModal:closed', createModal.content.reason);
          }
        }
        subscription.unsubscribe();
      });
  }

  edit(data): void {
    const editModal = this.settings.ModalService
      .show(this.settings.editComponent || this.settings.createComponent, {
        initialState: { data }
      });
    this.emitEvent('editModal:opened');
    const subscription = this.settings.ModalService.onHidden
      .subscribe(() => {
        if (editModal.content) {
          if (editModal.content.result) {
            this.emitEvent('editModal:completed', editModal.content.result);
          } else {
            this.emitEvent('editModal:closed', editModal.content.reason);
          }
        }
        subscription.unsubscribe();
      });
  }

  eventListener(event: Event) {
    switch (event.event) {
      case 'initialized':
      case 'createModal:completed':
      case 'editModal:completed':
      case 'delete:success':
      this.getList();
      break;
      case 'getList:started':
      case 'getList:success':
      case 'getList:failed':
      case 'getList:ended':
      case 'deleteModal:opened':
      case 'deleteModal:closed':
      case 'deleteModal:completed':
      case 'delete:started':
      case 'delete:failed':
      case 'delete:ended':
      case 'createModal:opened':
      case 'createModal:closed':
      case 'editModal:opened':
      case 'editModal:closed':
      default:
        break;
    }
  }

  private emitEvent(event: string, data?: any) {
    if (this.eventListener) {
      this.eventListener({
        event: event,
        data: data
      });
    }
  }

  isLoading():boolean {
    return Object.keys(this.loading).reduce((loading, key):boolean => {
      return loading || this.loading[key];
    }, false);
  }

  hasError():boolean {
    return Object.keys(this.errors).reduce((hasError, key):boolean => {
      return hasError || !!this.errors[key];
    }, false);
  }
}
