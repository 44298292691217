/* tslint:disable */

declare var Object: any;
export interface SmsInterface {
  "messageId": string;
  "text"?: string;
  "type": string;
  "dateCreated"?: Date;
  "dateUpdated"?: Date;
  "status"?: string;
  "from"?: string;
  "to"?: string;
  "id"?: number;
}

export class Sms implements SmsInterface {
  "messageId": string;
  "text": string;
  "type": string;
  "dateCreated": Date;
  "dateUpdated": Date;
  "status": string;
  "from": string;
  "to": string;
  "id": number;
  constructor(data?: SmsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Sms`.
   */
  public static getModelName() {
    return "Sms";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Sms for dynamic purposes.
  **/
  public static factory(data: SmsInterface): Sms{
    return new Sms(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Sms',
      plural: 'Sms',
      path: 'Sms',
      idName: 'id',
      properties: {
        "messageId": {
          name: 'messageId',
          type: 'string'
        },
        "text": {
          name: 'text',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "dateCreated": {
          name: 'dateCreated',
          type: 'Date'
        },
        "dateUpdated": {
          name: 'dateUpdated',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'string'
        },
        "to": {
          name: 'to',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
